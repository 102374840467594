import { Box } from "@chakra-ui/react";
import BranchServices from "../../components/branches/reports/BranchServices";
import {
  ARRIVAL_RATES_FREQUENCIES,
  reportDescriptions,
} from "../../store/constants";
import FilterForm from "../../components/FilterForm";
import { useSelector } from "react-redux";
import _ from "lodash";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";

const ArrivalRatesPerService = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { tickets, branches, organizationId, loading, reportRange, branchId } =
    useSelector((state) => state.defaultReducer);
  const ticketsClone = _.cloneDeep(tickets);
  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (branch) =>
        branch.organizationId === organizationId && Number(branch.reportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));

  //separate the data into branches
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  ticketsClone.forEach((data) => {
    const branchId = data.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(data);
    }
  });

  const title = `${reportIndex || ""}. Arrival Rates Per Service`;
  console.log(title);

  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{title}</h1>
              <p>
                {
                  getReportDescription(reportRange)
                    .BranchesArrivalRatesPerService
                }
              </p>
              {Object.keys(branchesMap).map((branchId, index) => {
                return (
                  <BranchServices
                    branchIndex={index}
                    data={branchesMap[branchId]}
                    frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                    branchId={branchId}
                    reportIndex={reportIndex}
                  />
                );
              })}
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerService;
