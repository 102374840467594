import {
  Box,
  Center,
  Heading,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import ArrivalRatesLineGraph from "../../ArrivalRatesLineGraph";
import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../../store/constants";
import ArrivalHeatmap from "../../ArrivalHeatmap";
import { useSelector } from "react-redux";
import { getReadableKeyFromObject } from "../../../store/helpers";
import { formatNumber } from "../../../store/helpers";

const BranchServicesTable = ({
  data,
  frequency,
  serviceId,
  index,
  branchName,
  reportIndex,
  branchIndex,
}) => {
  const { branches, hoursPerDay, services } = useSelector(
    (state) => state.defaultReducer
  );

  const serviceName =
    services.find((service) => serviceId === service.id)?.name || "Unnamed";

  const arrivalTimes = data.map((entry) => dayjs.unix(entry.created));

  const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
    const formattedDate = arrivalTime.format("YYYY-MM-DD");

    acc[formattedDate] = (acc[formattedDate] || 0) + 1;

    return acc;
  }, {});

  const rates = Object.entries(arrivalTimesPerDay).map(([date, count]) => ({
    date,
    count:
      frequency === ARRIVAL_RATES_FREQUENCIES.HOUR
        ? count / hoursPerDay
        : count,
  }));
  rates.sort((a, b) => new Date(a.date) - new Date(b.date));

  const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
  const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
  const total = rates.reduce((sum, entry) => sum + entry.count, 0);
  const average = rates.length > 0 ? total / rates.length : 0;

  const heatMapTitle = serviceName + " Time of Day Chart";

  const title = `${reportIndex || ""}.${branchIndex + 1}.${index + 1}. ${serviceName}`;
  console.log(title)
  return (
    <>
      <Box className="reportContainer" mt="20px">
        <h3>
          {title}
        </h3>
        <Tag>
          <p className="branchTag">{branchName} Branch</p>
        </Tag>
        {!data.length && (
          <Text fontSize="sm">No data available for this report</Text>
        )}


        {data.length ? (
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0"> Total Visitors</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th>Min</Th>
                <Th>Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{index + 1}</Td>
                <Td borderRight="1px solid #dadce0">{formatNumber(total)}</Td>
                <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
                <Td>{formatNumber(min)}</Td>
                <Td>{formatNumber(max)}</Td>
              </Tr>

              <Tr>
                <Td colSpan={5}>
                  <Box mt="20px" mb="20px">
                    <ArrivalRatesLineGraph
                      arrivalTimesPerDay={arrivalTimesPerDay}
                      frequency={frequency}
                      level={LEVELS.BRANCH}
                      height={"300px"}
                      title={`${serviceName} Arrival Rates, Day by Day Comparison`}
                    />
                  </Box>
                  {frequency === ARRIVAL_RATES_FREQUENCIES.HOUR ? (
                    <ArrivalHeatmap
                      title={heatMapTitle}
                      arrivalTimes={arrivalTimes}
                    />
                  ) : (
                    <></>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default BranchServicesTable;
