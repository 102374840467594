import {
  Box,
  Center,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../../store/constants";
import ArrivalRatesLineGraph from "../../ArrivalRatesLineGraph";
import { formatNumber } from "../../../store/helpers";

const TicketsByBranchTable = ({ branchData, index, reportIndex }) => {
  const {
    branchName,
    min,
    max,
    total,
    average,
    totalPct,
    arrivalTimesPerDay,
    numServices,
  } = branchData;

  const title = `${reportIndex || ""}.${index + 1}. ${branchName} Branch`;
  console.log(title);
  return (
    <Box>
      <h2>
        {title}
      </h2>
      <Text>{numServices} services</Text>

      {total ? (
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Tickets</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{index + 1}</Td>
              <Td borderRight="1px solid #dadce0">
                {" "}
                <Text color={"var(--green)"}>
                  {formatNumber(total)}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    ({totalPct.toFixed(2)}%)
                  </Box>
                </Text>
              </Td>

              <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
              <Td>{formatNumber(min)}</Td>
              <Td>{formatNumber(max)}</Td>
            </Tr>

            <Tr>
              <Td colSpan={5}>
                <Box mt="20px" mb="20px">
                  <ArrivalRatesLineGraph
                    arrivalTimesPerDay={arrivalTimesPerDay}
                    level={LEVELS.ORGANIZATION}
                    frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                    title={`${branchName} tickets, Day by Day Comparison`}
                    height={"300px"}
                  />
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default TicketsByBranchTable;
