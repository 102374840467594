import { Box, Heading } from "@chakra-ui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { generateDateArray } from "../../../store/helpers";

const AgentTicketStatusGraphs = ({ data, title }) => {
  const { workWeek, dateRange } = useSelector((store) => store.defaultReducer);
  const { completed, noShow, unattended } = data.reduce(
    (acc, b) => {
      const status = Number(b.status);
      if (status === 3) acc.completed.push(b);
      else if (status === 2) acc.noShow.push(b);
      else if (status < 2) acc.unattended.push(b);
      return acc;
    },
    { completed: [], noShow: [], unattended: [] }
  );

  const mapArrivalTimes = (entries) =>
    entries.map((entry) => dayjs.unix(entry.created));

  const completedArrivalTimes = mapArrivalTimes(completed);
  const noShowArrivalTimes = mapArrivalTimes(noShow);
  const unattendedArrivalTimes = mapArrivalTimes(unattended);
  const totalArrivalTimes = mapArrivalTimes(data);

  const generatePerDayData = (arrivals) =>
    arrivals.reduce((acc, arrivalTime) => {
      const formattedDate = arrivalTime.format("YYYY-MM-DD");
      acc[formattedDate] = (acc[formattedDate] || 0) + 1;
      return acc;
    }, {});

  const totalArrivalTimesPerDay = generatePerDayData(totalArrivalTimes);
  const completedArrivalTimesPerDay = generatePerDayData(completedArrivalTimes);
  const unattendedArrivalTimesPerDay = generatePerDayData(
    unattendedArrivalTimes
  );
  const noShowArrivalTimesperDay = generatePerDayData(noShowArrivalTimes);

  const processData = (data) =>
    Object.entries(data)
      .map(([date, count]) => ({
        date,
        count,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((rate) => ({
        date: new Date(rate.date).getDate(),
        total: rate.count,
        fullDate: new Date(rate.date),
      }));

  const noShowData = processData(noShowArrivalTimesperDay);
  const unattendedData = processData(unattendedArrivalTimesPerDay);
  const completedData = processData(completedArrivalTimesPerDay);
  const totalData = processData(totalArrivalTimesPerDay);

  const mergeData = (data, property) =>
    totalData.forEach((item) => {
      item[property] = data.find((val) => val.date === item.date)?.total || 0;
    });

  mergeData(completedData, "completed");
  mergeData(noShowData, "no_show");
  mergeData(unattendedData, "unattended");
  
  const datesBetween = generateDateArray(dateRange[0], dateRange[1]);

  const finalData = datesBetween
    .map((fullDate, i) => {
      let date = dayjs(fullDate).date();
      const correspondingData = totalData.find(
        (entry) =>
          dayjs(entry.fullDate).format("MM-DD-YYYY") ===
          dayjs(fullDate).format("MM-DD-YYYY")
      );

      if (workWeek.includes(new Date(fullDate).getDay()))
        return {
          date,
          total: correspondingData?.total || 0,
          completed: correspondingData?.completed || 0,
          no_show: correspondingData?.no_show || 0,
          unattended: correspondingData?.unattended || 0,
        };

      return null;
    })
    .filter(Boolean);

  return (
    <>
      <Box width="100%" height="200px">
        <ResponsiveContainer>
          <LineChart
            data={finalData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#EBEBEB" vertical={false} />
            <XAxis
              dataKey="date"
              name="Day"
              axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
            />
            <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
            <Tooltip />
            <Legend width="100%" />
            <Line
              type="monotone"
              strokeWidth={1.6}
              dataKey="total"
              name="Total"
              stroke={"var(--blue)"}
              dot={{ fill: "var(--blue)", r: 2 }}
              activeDot={{ r: 4 }}
            />
            <Line
              type="monotone"
              strokeWidth={1.6}
              dataKey="completed"
              name="Completed"
              stroke={"var(--green)"}
              dot={{ fill: "var(--green)", r: 2 }}
              activeDot={{ r: 4 }}
            />
            <Line
              type="monotone"
              strokeWidth={1.6}
              dataKey="no_show"
              name="No Show"
              stroke={"var(--red)"}
              dot={{ fill: "var(--red)", r: 2 }}
              activeDot={{ r: 4 }}
            />
            <Line
              type="monotone"
              strokeWidth={1.6}
              dataKey="unattended"
              name="Unattended"
              stroke={"var(--orange)"}
              dot={{ fill: "var(--orange)", r: 2 }}
              activeDot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
        <Heading
          size="sm"
          fontWeight={400}
          fontSize="14px"
          color="#535353"
          mb="20px"
          textAlign="center"
        >
          {title}
        </Heading>
      </Box>
    </>
  );
};

export default AgentTicketStatusGraphs;
