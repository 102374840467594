import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
import { Box, Text } from "@chakra-ui/react";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import AgentTicketsPerServiceTable from "../../tables/daily/AgentTicketsPerServiceTable";

const BranchAgentTicketsPerService = ({
  branchId,
  reportIndex,
  branchIndex,
}) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
    users,
  } = useSelector((state) => state.defaultReducer);
  const usersClone = _.cloneDeep(users);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  let branchesClone = _.cloneDeep(branches);

  branchesClone = branchesClone.filter(
    (a) => a.reportStatus != null && Number(a.reportStatus)
  );

  const branchData = ticketsClone.filter((a) => a.branchId === branchId);

  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);

  const servicesMap = {};
  branchServices?.forEach((serviceId) => {
    servicesMap[serviceId] = [];
  });

  // Iterate over the data array and group objects by serviceId
  branchData.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const branchName =
    branchesClone.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  const servicesData = Object.keys(servicesMap).map((serviceId) => {
    const serviceData = servicesMap[serviceId];
    const serviceName =
      services.find((service) => serviceId === service.id)?.name || "Unnamed";

    //split the services into agents.

    const agents = usersClone.filter(
      (user) =>
        user.organizationId === organizationId &&
        user.branchId === branchId &&
        user.services.includes(serviceId)
    );

    const agentsMap = {};
    agents.forEach((agent) => {
      agentsMap[agent.id] = [];
    });

    // Iterate over the data array and group objects by agentsId

    serviceData.forEach((item) => {
      const agentId = item.manage?.userId;

      if (Array.isArray(agentsMap[agentId])) {
        agentsMap[agentId].push(item);
      }
    });

    //agentsData
    const agentsData = Object.keys(agentsMap)
      .map((agentId, index) => {
        const data = agentsMap[agentId];
        const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
        const hourlyData = hoursArray
          .map((hour) => {
            const hourTickets = data.filter(
              (a) => dayjs.unix(a.created).format("h A") === hour
            );

            if (workDay.includes(hour))
              return {
                hour,
                count: hourTickets.length,
              };

            return null;
          })
          .filter(Boolean);

        const min = Math.min(...hourlyData.map((obj) => obj.count)) || 0;
        const max = Math.max(...hourlyData.map((obj) => obj.count)) || 0;
        const total = hourlyData.reduce((acc, obj) => acc + obj.count, 0) || 0;
        const average = total / hourlyData.length || 0;

        const user = users.find((user) => user.id === agentId);

        //Completed
        const numCompleted =
          data.filter((b) => Number(b.status) === 3).length || 0;

        const numNoShow =
          data.filter((b) => Number(b.status) === 2).length || 0;

        const numUnattended =
          data.filter((b) => Number(b.status) < 2).length || 0;
        const totalAgentTickets = numCompleted + numUnattended + numNoShow;

        const pctCompleted = (numCompleted / totalAgentTickets) * 100 || 0;
        const pctNoShow = (numNoShow / totalAgentTickets) * 100 || 0;
        const pctUnattended = (numUnattended / totalAgentTickets) * 100 || 0;
        const agentName = `${user.name} ${user.lastname}`;

        return {
          min,
          max,
          total,
          average,
          numCompleted,
          numNoShow,
          numUnattended,
          totalAgentTickets,
          pctCompleted,
          pctNoShow,
          pctUnattended,
          agentName,
        };
      })
      .sort((a, b) => {
        // Sort by total tickets in descending order
        if (b.total - a.total !== 0) {
          return b.total - a.total;
        }
        // If totalTickets is the same, sort alphabetically by name
        return a.agentName.localeCompare(b.agentName);
      });

    return {
      serviceName,
      agentsData,
      totalServiceData: serviceData.length,
    };
  });

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);

  return (
    <Box>
      <h2>{title}</h2>
      <Text>{branchServices.length} services.</Text>

      {servicesData.map((data, index) => {
        return (
          <AgentTicketsPerServiceTable
            index={index}
            serviceData={data}
            branchIndex={branchIndex}
            reportIndex={reportIndex}
            branchName={branchName}
          />
        );
      })}
    </Box>
  );
};

export default BranchAgentTicketsPerService;
