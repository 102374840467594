import { connect } from "react-redux";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  updateReportRange,
  updateOrganizationId,
} from "../store/actions/defaultAction";

import Nav from "./sidebar/Nav";

function Sidebar(props) {
  const { organizationId } = useSelector(
    (state) => state.defaultReducer
  );

  return (
    <Box
      className="sidebar"
      width="250px"
      backgroundColor="gray.200"
      p={4}
      position="fixed"
      height="100vh"
      overflowY="auto" // Add this line for the scrollbar effect
    >
      <Box>cx-qm-reporting</Box>

      {organizationId && organizationId.length ? (
        <Nav />
      ) : (
        <Box padding="20px">Please select an organization to get started</Box>
      )}
    </Box>
  );
}

export default connect(null, {
  updateReportRange,
  updateOrganizationId,
})(Sidebar);
