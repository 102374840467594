import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { generateHoursArray } from "../../../../store/helpers";
import dayjs from "dayjs";
import OrganizationArrivalRatesPerDayTable from "../../tables/daily/OrganizationArrivalRatesPerHourTable";
import LineGraph from "../../../daily/LineGraph";
const ArrivalRatesPerHour = ({ reportIndex }) => {
  const { tickets, dateRange, workDay } = useSelector(
    (state) => state.defaultReducer
  );

  const ticketsClone = _.cloneDeep(tickets);

  const hoursBetween = generateHoursArray(dateRange[0], dateRange[1]);

  const chart = hoursBetween
    .map((hour) => {
      const hourTickets = ticketsClone.filter(
        (a) => dayjs.unix(a.created).format("h A") === hour
      );

      if (workDay.includes(hour))
        return {
          hour,
          total: hourTickets.length,
        };
      return null;
    })
    .filter(Boolean);

  const min = Math.min(...chart.map((a) => a.total)) || 0;
  const max = Math.max(...chart.map((a) => a.total)) || 0;
  const total = chart.reduce((a, b) => a + b.total, 0) || 0;
  const average = chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
  const title = `${reportIndex}.1. Entire Organization`;
  console.log(title);

  return (
    <Box className="reportContainer" mt="20px">
      <OrganizationArrivalRatesPerDayTable
        average={average}
        min={min}
        max={max}
        total={total}
        title={title}
      />

      <Box mt="20px" mb="20px">
        <LineGraph
          chart={chart}
          height={"300px"}
          title={"Entire Organization Arrival Rates, Hour by Hour Comparison"}
        />
      </Box>
    </Box>
  );
};

export default ArrivalRatesPerHour;
