import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Stack,
  Badge,
  Text,
  extendTheme,
  Select,
  HStack,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import { ARRIVAL_RATES_FREQUENCIES } from "../../store/constants";
import { getReadableKeyFromObject } from "../../store/helpers";
import { formatNumber } from "../../store/helpers";

const ArrivalRatesTable = ({ arrivalTimesPerDay, frequency, reportIndex }) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  const id = useId();

  const { hoursPerDay } = useSelector((state) => state.defaultReducer);

  const rates = Object.entries(arrivalTimesPerDay).map(([date, count]) => ({
    date,
    count:
      frequency === ARRIVAL_RATES_FREQUENCIES.HOUR
        ? count / hoursPerDay
        : count,
  }));
  rates.sort((a, b) => new Date(a.date) - new Date(b.date));

  const min = Math.min(...rates.map((obj) => obj.count));
  const max = Math.max(...rates.map((obj) => obj.count));
  const total = rates.reduce((sum, entry) => sum + entry.count, 0);
  const average = total / rates.length;

  const title = `${reportIndex}.1. Entire Organization`;
  console.log(title);
  return (
    <Box mt={10}>
      <h3>{title}</h3>
      <Box>
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Visitors</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={id}>
              <Td>1</Td>
              <Td borderRight="1px solid #dadce0" color={"var(--darkGray)"}>
                {formatNumber(total)}
              </Td>
              <Td borderRight="1px solid #dadce0">
                <Text color={"var(--blue)"}>{formatNumber(average)}</Text>
              </Td>
              <Td>
                <Text color={"var(--blue)"}>{formatNumber(min)}</Text>
              </Td>
              <Td>
                <Text color={"var(--blue)"}>{formatNumber(max)}</Text>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default ArrivalRatesTable;
