import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';

import {
    Box,
} from '@chakra-ui/react';

import AllTicketsTable from "../tables/AllTicketsTable";
import { useSelector } from "react-redux";

import { completed, noShow } from "../../../store/constants";
import { getNonWorkDays, getNonWorkDates, generateDateArray } from "../../../store/helpers";

const Tickets = ({
    reportIndex
}) => {
    const { tickets, workWeek, organizationId, dateRange } = useSelector((state)=> state.defaultReducer)
    const workWeekClone = _.cloneDeep(workWeek);
    let ticketsClone = _.cloneDeep(tickets);
    ticketsClone = ticketsClone.filter( a => a.organizationId === organizationId );

    // all tickets
    const managementStats = [{
        name: 'Entire Organization',
        total: ticketsClone.length,
        completed: ticketsClone.filter( a => a.status === completed ).length,
        noShow: ticketsClone.filter( a => a.status === noShow ).length,
        unattended: ticketsClone.filter( a => a.status < 2 ).length,
    }];
    managementStats[0].pctCompleted = (managementStats[0].completed / managementStats[0].total) * 100 || 0;
    managementStats[0].pctNoShow = (managementStats[0].noShow / managementStats[0].total) * 100 || 0;
    managementStats[0].pctUnattended = (managementStats[0].unattended / managementStats[0].total) * 100 || 0;

    // use starting date from date range picker dateRange
    const dateObject = dayjs.unix(dateRange[0]);
    const daysInMonth = dateObject.daysInMonth();
    // create an array of days in the month
    const daysArray = Array.from(Array(daysInMonth).keys());
    const datesArray = generateDateArray(dateRange[0], dateRange[1]);

    // for each day do a count of tickets created on that day
    // count total tickets per day, completed, noshow and unattended

    let ticketsPerDay = datesArray.map((fullDate)=>{
        let day = dayjs(fullDate).date();
        const dayTickets = ticketsClone.filter( a => dayjs.unix(a.created).format('D').toString() === day.toString() );
        const dayCompleted = dayTickets.filter( a => a.status === completed ).length;
        const dayNoShow = dayTickets.filter( a => a.status === noShow ).length;
        const dayUnattended = dayTickets.filter( a => a.status < 2 ).length;

        return {
            day,
            total: dayTickets.length,
            completed: dayCompleted,
            noShow: dayNoShow,
            unattended: dayUnattended,
        }
    })

    // non work days. use workWeek to get non work days
    const nonWorkDays = getNonWorkDays(workWeekClone);
    // get non work dates from the calender month
    const nonWorkDates = getNonWorkDates(dateObject, nonWorkDays, daysInMonth);

    ticketsPerDay = ticketsPerDay.filter( a => !nonWorkDates.includes(a.day.toString()))

    // get average of tickets per day
    const totalTickets = ticketsPerDay.reduce( (acc, a) => acc + a.total, 0);
    const averageTicketsPerDay = totalTickets / daysInMonth;
    // get min and max of tickets per day
    const minTicketsPerDay = Math.min(...ticketsPerDay.map( a => a.total));
    const maxTicketsPerDay = Math.max(...ticketsPerDay.map( a => a.total));

    // add average, min & max to managementStats
    managementStats[0].averageTicketsPerDay = averageTicketsPerDay.toFixed(2);
    managementStats[0].minTicketsPerDay = minTicketsPerDay;
    managementStats[0].maxTicketsPerDay = maxTicketsPerDay;

    return (
        <Box>
            <AllTicketsTable 
            table={managementStats} 
            chart={ticketsPerDay} 
            reportIndex={reportIndex} 
        />
        </Box>
    );
};


  
export default Tickets;
  