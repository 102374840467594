import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Center,
  Divider,
  HStack,
  Button,
  Select,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import "../assets/css/Filter.css";
import { getTickets, setDateRange } from "../store/actions/defaultAction";
import { arraysAreEqual, generateDateArray } from "../store/helpers";
import { REPORT_RANGES } from "../store/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateReportRange } from "../store/actions/defaultAction";
import BranchSelection from "./filterForm/BranchSelection";
import OrganizationSelection from "./filterForm/OrganizationSelection";

function FilterForm(props) {
  const [showBranchAndDateSelection, setShowBranchAndDateSelection] =
    useState(false);

  const [showSearchButton, setShowSearchButton] = useState(false);
  const dispatch = useDispatch();
  const { dateRange, workWeek, reportRange, organizationId, branchId } = props;

  useEffect(() => {
    setShowBranchAndDateSelection(!!organizationId);
    setShowSearchButton(!!organizationId);
  }, [organizationId]);

  useEffect(() => {
    ///for week range, we need 5 days in the workWeek
    //With this implementation Friday of prev-week to thursday of following week will work
    const datesArray = generateDateArray(dateRange[0], dateRange[1]);
    const days = datesArray
      .map((fullDate, i) => {
        let day = dayjs(fullDate).day();
        if (workWeek.includes(day)) return day;

        return null;
      })
      .filter(Boolean)
      .sort((a, b) => a - b);

    if (reportRange === REPORT_RANGES.WEEK) {
      //make sure days[] is a workweek
      if (
        !arraysAreEqual(
          workWeek.sort((a, b) => a - b),
          days
        )
      ) {
        toast.error("Please select a valid workweek");
        setShowSearchButton(false);
      } else {
        setShowSearchButton(true);
      }
    }

    if (reportRange === REPORT_RANGES.MONTH) {
      setShowSearchButton(!!organizationId);
    }
    if (reportRange === REPORT_RANGES.WEEK && !organizationId.length) {
      setShowSearchButton(false);
    }
    if (
      reportRange === REPORT_RANGES.DAY &&
      dayjs.unix(dateRange[0]).format("YYYY-MM-DD") !==
        dayjs.unix(dateRange[1]).format("YYYY-MM-DD")
    ) {
      toast.error("Please select a valid date range");
      setShowSearchButton(false);
    } else {
      setShowSearchButton(true);
    }
  }, [dateRange, reportRange, workWeek, organizationId]);

  const handleDateRangeUpdate = (e) => {
    const start = dayjs(e[0]).unix();
    const end = dayjs(e[1]).unix();

    props.setDateRange([start, end]);
  };

  return (
    <Box
      className="filterForm"
      p={5}
      fontSize={14}
      borderWidth={1}
      borderColor="#ececec"
      borderRadius={5}
      mt={10}
    >
      <Text fontSize="lg">Filter tickets</Text>
      <Center height="20px">
        <Divider />
      </Center>
      <HStack spacing="24px" mt={5}>
        <Box>
          <Text fontSize={14}>Report range</Text>
          <Select
            placeholder="Select report range"
            value={reportRange}
            onChange={(e) => dispatch(updateReportRange(e.target.value))}
          >
            <option value={REPORT_RANGES.MONTH}>Monthly</option>
            <option value={REPORT_RANGES.WEEK}>Weekly</option>
            <option value={REPORT_RANGES.DAY}>Daily</option>
          </Select>
        </Box>
        <OrganizationSelection />
        {showBranchAndDateSelection && (
          <>
            <BranchSelection />
            <Box>
              <Text fontSize={14}>Date range</Text>
              <DateRangePicker
                clearIcon={null}
                format="dd-MM-y"
                onChange={handleDateRangeUpdate}
                value={[
                  new Date(dayjs.unix(dateRange[0]).format("YYYY-MM-DD")),
                  new Date(dayjs.unix(dateRange[1]).format("YYYY-MM-DD")),
                ]}
              />
            </Box>
          </>
        )}
      </HStack>
      {showSearchButton && (
        <Button
          mt={5}
          onClick={() =>
            props.getTickets(props.organizationId, dateRange, branchId || null)
          }
        >
          Search
        </Button>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { defaultReducer } = state;

  return {
    organizationId: defaultReducer.organizationId,
    branchId: defaultReducer.branchId,
    dateRange: defaultReducer.dateRange,
    workWeek: defaultReducer.workWeek,
    reportRange: defaultReducer.reportRange,
    organizations: defaultReducer.organizations,
    branches: defaultReducer.branches,
  };
};

export default connect(mapStateToProps, { getTickets, setDateRange })(
  FilterForm
);
