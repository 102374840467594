import { Box } from "@chakra-ui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import { TICKET_STATUS } from "../../../../store/constants";
import OrganizationTicketsPerServiceTable from "../../tables/daily/OrganizationTicketsPerService";

const OrganizationTicketsPerService = ({ reportIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);
  const servicesClone = _.cloneDeep(services);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const branchesClone = _.cloneDeep(branches);

  const orgBranches = branchesClone.filter(
    (a) => a.organizationId === organizationId && Number(a.reportStatus)
  );
  let orgServices = [];
  orgBranches.forEach((branch) => {
    const branchServices =
      servicesSubscriptionsClone.find(
        (service) => service.branchId === branch.id
      )?.services || [];
    orgServices.push(...branchServices);
  });

  let updatedServices = checkServiceHasChildren(servicesClone).filter(
    (service) => orgServices?.includes(service.id) && !service.hasChildren
  );
  const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);

  // create object to hold stats
  const serviceStatsObject = {
    total: 0,
    completed: 0,
    noShow: 0,
    unattended: 0,
    pctCompleted: 0,
    pctNoShow: 0,
    pctUnattended: 0,
    averageTicketsPerHour: 0,
    minTicketsPerHour: 0,
    maxTicketsPerHour: 0,

    hours: hoursArray
      .map((hour, i) => {
        if (workDay.includes(hour))
          return {
            hour,
            total: 0,
            completed: 0,
            noShow: 0,
            unattended: 0,
          };
        return null;
      })
      .filter(Boolean),
  };

  // create array of objects to hold stats for each service

  const managementStats = updatedServices
    .filter((a) => !a.hasChildren)
    .map((a) => ({
      ..._.cloneDeep(serviceStatsObject),
      id: a.id,
      name: a.name,
    }));

  ticketsClone.forEach((a) => {
    const serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
    if (serviceIndex !== -1) {
      // unix timestamp to Date using dayjs, then get the day index in the array with (-1)
      const hourIndex = managementStats[serviceIndex].hours.findIndex(
        (b) => b.hour === dayjs.unix(a.created).format("h A")
      );
      // Update total tickets per day and total tickets per month
      if (managementStats[serviceIndex].hours[hourIndex]) {
        managementStats[serviceIndex].total =
          managementStats[serviceIndex].total + 1;
        managementStats[serviceIndex].hours[hourIndex].total =
          managementStats[serviceIndex].hours[hourIndex].total + 1;

        if (a.status === TICKET_STATUS.COMPLETED) {
          managementStats[serviceIndex].completed += 1;
          managementStats[serviceIndex].hours[hourIndex].completed += 1;
        } else if (a.status === TICKET_STATUS.NO_SHOW) {
          managementStats[serviceIndex].noShow += 1;
          managementStats[serviceIndex].hours[hourIndex].noShow += 1;
        } else if (a.status < 2) {
          managementStats[serviceIndex].unattended += 1;
          managementStats[serviceIndex].hours[hourIndex].unattended += 1;
        }
      }
    }
  });

  // update pct completed, no show, and unattended
  managementStats.forEach((a) => {
    a.pctCompleted = (a.completed / a.total) * 100 || 0;
    a.pctNoShow = (a.noShow / a.total) * 100 || 0;
    a.pctUnattended = (a.unattended / a.total) * 100 || 0;

    // avg, min, max
    const totalTickets = a.total;
    a.averageTicketsPerHour = (
      totalTickets / serviceStatsObject.hours.length
    ).toFixed(2);
    let minTicketsPerHour = (a.minTicketsPerHour = Math.min(
      ...a.hours.map((c) => c.total)
    ));
    let maxTicketsPerHour = (a.maxTicketsPerHour = Math.max(
      ...a.hours.map((c) => c.total)
    ));
    a.minTicketsPerHour = isFinite(minTicketsPerHour) ? minTicketsPerHour : 0;
    a.maxTicketsPerHour = isFinite(maxTicketsPerHour) ? maxTicketsPerHour : 0;
  });

  // sort by total in descending order, followed by all empty data sets sorted alphabetically
  managementStats.sort((a, b) => {
    // Sort by total in descending order
    if (b.total - a.total !== 0) {
      return b.total - a.total;
    }
    // If total is the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  return (
    <Box>
      <OrganizationTicketsPerServiceTable
        branchId={null}
        data={managementStats}
        numServices={null}
        branchName={null}
        reportIndex={reportIndex}
        branchIndex={null}
      />
    </Box>
  );
};

export default OrganizationTicketsPerService;
