import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";
import ArrivalRatesPerDayReport from "../../../components/organization/reports/daily/OrganizationArrivalRatesPerHour";

const OrganizationArrivalRatesPerHour = ({
  showFilterForm = true,
  reportIndex = 1,
}) => {
  const { reportRange, loading, tickets } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTitle = `${reportIndex}. Arrival Rates Per Hour`;
  console.log(reportTitle);
  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{reportTitle}</h1>
              <p>{getReportDescription(reportRange).ArrivalRatesPerHour}</p>

              <ArrivalRatesPerDayReport reportIndex={reportIndex} />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrganizationArrivalRatesPerHour;
