import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import OrganizationServiceTimePerServiceTable from "../../../organization/tables/daily/OrganizationServiceTimePerServiceTable";
import { Box } from "@chakra-ui/react";
import { TICKET_STATUS } from "../../../../store/constants";

const BranchServiceTimePerService = ({
  branchId,
  reportIndex,
  branchIndex,
}) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);

  let ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  ticketsClone = ticketsClone.filter((a) => a.branchId === branchId);

  let updatedServices = checkServiceHasChildren(servicesClone);
  let subscribedServices = [];

  if (branchId) {
    subscribedServices =
      servicesSubscriptionsClone.find((a) => a.branchId === branchId)
        ?.services || [];
    if (subscribedServices == []) {
      console.log(
        branchId,
        subscribedServices,
        "Branch is not subscribed to any services"
      );
      return [];
    }
    // update services to only subscribed services
    updatedServices = updatedServices.filter((a) =>
      subscribedServices.includes(a.id)
    );

    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId
    )?.services;

    subscribedServices = checkServiceHasChildren(servicesClone).filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    );
  }

  const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);

  // create object to hold stats
  const serviceStatsObject = {
    sessionInSeconds: 0,
    completed: 0,
    avg: 0,
    min: 0,
    max: 0,

    hours: hoursArray
      .map((hour, i) => {
        if (workDay.includes(hour))
          return {
            hour,
            sessionInSeconds: 0,
            completed: 0,
            avg: 0,
          };
        return null;
      })
      .filter(Boolean),
  };

  // create array of objects to hold stats for each service
  const managementStats = updatedServices
    .filter((a) => !a.hasChildren)
    .map((a) => ({
      ..._.cloneDeep(serviceStatsObject),
      id: a.id,
      name: a.name,
    }));

  // loop through tickets and update stats
  ticketsClone
    .filter((a) => a.status === TICKET_STATUS.COMPLETED)
    .forEach((a) => {
      let serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
      if (serviceIndex === -1) {
        managementStats.push({
          ..._.cloneDeep(serviceStatsObject),
          id: a.serviceId,
          name: a.serviceName,
        });
        serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
      }

      const hourIndex = managementStats[serviceIndex].hours.findIndex(
        (b) => b.hour === dayjs.unix(a.created).format("h A")
      );
      if (hourIndex > -1) {
        managementStats[serviceIndex].completed += 1;
        managementStats[serviceIndex].hours[hourIndex].completed += 1;
        managementStats[serviceIndex].sessionInSeconds += a.sessionInSeconds;
        managementStats[serviceIndex].hours[hourIndex].sessionInSeconds +=
          a.sessionInSeconds;

        if (a.status === 3) {
          managementStats[serviceIndex].completed += 1;
          managementStats[serviceIndex].hours[hourIndex].completed += 1;
        }
      }
    });

  // update pct completed, no show, and unattended
  managementStats.forEach((a, serviceIndex) => {
    // for each service loop days and calculate avg
    a.hours.forEach((b, hourIndex) => {
      managementStats[serviceIndex].hours[hourIndex].avg =
        b.completed !== 0 ? (b.sessionInSeconds / b.completed).toFixed(2) : 0;
    });

    a.avg =
      a.completed !== 0 ? (a.sessionInSeconds / a.completed).toFixed(2) : 0;
    let min = Math.min(...a.hours.map((b) => b.avg));
    let max = Math.max(...a.hours.map((b) => b.avg));

    a.min = isFinite(min) ? min : 0;
    a.max = isFinite(max) ? max : 0;
  });

  // sort by sessionInSeconds in descending order, followed by all empty data sets sorted alphabetically
  managementStats.sort((a, b) => {
    // Sort by sessionInSeconds in descending order
    if (b.sessionInSeconds - a.sessionInSeconds !== 0) {
      return b.sessionInSeconds - a.sessionInSeconds;
    }
    // If sessionInSeconds is the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });
  managementStats.branchId = branchId;

  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;
  const data = {
    servicesData: managementStats,
    branchId,
    branchName,
    numServices: subscribedServices.length,
  };
  return (
    <Box>
      <OrganizationServiceTimePerServiceTable
        branchId={branchId}
        data={data.servicesData}
        numServices={data.numServices}
        branchName={branchName}
        reportIndex={reportIndex}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default BranchServiceTimePerService;
