import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { TICKET_STATUS } from "../../../../store/constants";
import { generateHoursArray } from "../../../../store/helpers";
import dayjs from "dayjs";
import OrganizationTicketsTable from "../../tables/daily/OrganizationTicketsTable";

const OrganizationTickets = ({ reportIndex }) => {
  const { tickets, dateRange, workDay } = useSelector(
    (state) => state.defaultReducer
  );
  const ticketsClone = _.cloneDeep(tickets);

  const managementStats = [
    {
      name: "Entire Organization",
      total: ticketsClone.length,
      completed: ticketsClone.filter(
        (a) => a.status === TICKET_STATUS.COMPLETED
      ).length,
      noShow: ticketsClone.filter((a) => a.status === TICKET_STATUS.NO_SHOW)
        .length,
      unattended: ticketsClone.filter((a) => a.status < 2).length,
    },
  ];

  managementStats[0].pctCompleted =
    (managementStats[0].completed / managementStats[0].total) * 100 || 0;

  managementStats[0].pctNoShow =
    (managementStats[0].noShow / managementStats[0].total) * 100 || 0;

  managementStats[0].pctUnattended =
    (managementStats[0].unattended / managementStats[0].total) * 100 || 0;

  const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);

  let ticketsPerHour = hoursArray
    .map((hour) => {
      const hourTickets = tickets.filter(
        (a) => dayjs.unix(a.created).format("h A") === hour
      );
      const dayCompleted = hourTickets.filter(
        (a) => a.status === TICKET_STATUS.COMPLETED
      ).length;
      const dayNoShow = hourTickets.filter(
        (a) => a.status === TICKET_STATUS.NO_SHOW
      ).length;
      const dayUnattended = hourTickets.filter((a) => a.status < 2).length;

      if (workDay.includes(hour))
        return {
          hour,
          total: hourTickets.length,
          completed: dayCompleted,
          noShow: dayNoShow,
          unattended: dayUnattended,
        };
      return null;
    })
    .filter(Boolean);

  // get average of tickets per day
  const totalTickets = ticketsPerHour.reduce((acc, a) => acc + a.total, 0);
  const averageTicketsPerHour = totalTickets / workDay.length;
  // get min and max of tickets per day
  let minTicketsPerHour = Math.min(...ticketsPerHour.map((a) => a.total));
  let maxTicketsPerHour = Math.max(...ticketsPerHour.map((a) => a.total));
  minTicketsPerHour = isFinite(minTicketsPerHour) ? minTicketsPerHour : 0;
  maxTicketsPerHour = isFinite(maxTicketsPerHour) ? maxTicketsPerHour : 0;

  // add average, min & max to managementStats
  managementStats[0].averageTicketsPerHour = averageTicketsPerHour.toFixed(2);
  managementStats[0].minTicketsPerHour = minTicketsPerHour;
  managementStats[0].maxTicketsPerHour = maxTicketsPerHour;
  managementStats[0].chart = ticketsPerHour;

  console.log(managementStats);
  return (
    <Box>
      <OrganizationTicketsTable
        table={managementStats}
        chart={ticketsPerHour}
        reportIndex={reportIndex}
      />
    </Box>
  );
};

export default OrganizationTickets;
