import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACTION_TYPES } from "./types";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

const fetchData = async (
  endpoint,
  params,
  singleResponse,
  dispatch,
  actionType
) => {
  try {
    const res = await axios.get(endpoint, { params });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    toast.success(endpoint + ": Data fetched successfully");
    dispatch({ type: actionType, payload });
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
  dispatch({ type: "SET_LOADING", payload: false });
};

// get tickets
export const getTickets =
  (organizationId, dateRange = null, branchId = null) =>
  async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true }); //This could go directkly to getData
    const endpoint = "/management/tickets";
    const payload = {
      organizationId,
      createdFrom: dateRange
        ? dateRange[0]
        : dayjs().subtract(1, "month").startOf("month").unix(),
      createdTo: dateRange ? dateRange[1] : dayjs().unix(),
      branchId,
    };
    const actionType = "GET_TICKETS";
    const singleResponse = 0;
    toast.info("Fetching data");
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

// GET_SERVICES
export const getOrganizations =
  (organizationId, branchId) => async (dispatch) => {
    const endpoint = "/admin/organizations/";
    const payload = {
      organizationId,
    };
    const actionType = "GET_ORGANIZATIONS";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };
// GET_SERVICES
export const getServices = (organizationId, branchId) => async (dispatch) => {
  const endpoint = "/apps/qm/services/";
  const payload = {
    organizationId,
    branchId,
  };
  const actionType = "GET_SERVICES";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};
// GET_SERVICES_SUBSCRIPTIONS
export const getServicesSubscriptions =
  (organizationId, branchId) => async (dispatch) => {
    const endpoint = "/apps/qm/serviceSubscriptions/";
    const payload = {
      organizationId,
      branchId,
    };
    const actionType = "GET_SERVICES_SUBSCRIPTIONS";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };
// GET_USERS
export const getUsers =
  (organizationId, branchId, dateRange = null) =>
  async (dispatch) => {
    const endpoint = "/iam/users/";
    const payload = {
      organizationId,
      branchId,
    };
    const actionType = "GET_USERS";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };
// GET_BRANCHES
export const getBranches =
  (organizationId, branchId, dateRange = null) =>
  async (dispatch) => {
    const endpoint = "/admin/branches/";
    const payload = {
      organizationId,
      branchId,
    };
    const actionType = "GET_BRANCHES";
    const singleResponse = 0;
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  };

// search tickets
export const setDateRange = (dateRange) => async (dispatch) => {
  dispatch({
    type: "SET_DATE_RANGE",
    payload: dateRange,
  });
};

export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_REPORT_RANGE",
    payload,
  });
};

export const updateOrganizationId = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_ORGANIZATION_ID",
    payload,
  });

  dispatch(getBranches(payload));
  dispatch(getServices(payload));
  dispatch(getServicesSubscriptions(payload));
  dispatch(getUsers(payload));
};

export const updateBranchId =
  ({ branchId, organizationId }) =>
  (dispatch) => {
    dispatch({
      type: "UPDATE_BRANCH_ID",
      payload: branchId,
    });

    dispatch(getBranches(organizationId, branchId));
    dispatch(getServices(organizationId, branchId));
    dispatch(getServicesSubscriptions(organizationId, branchId));
    dispatch(getUsers(organizationId, branchId));
  };
