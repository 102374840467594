import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { Box } from "@chakra-ui/react";

import {
  checkServiceHasChildren,
  getNonWorkDays,
  getNonWorkDates,
  generateDateArray,
} from "../../../store/helpers";
import { completed, noShow } from "../../../store/constants";

import TicketsPerServiceTable from "../../../components/organization/tables/TicketsPerServiceTable";

const TicketsByService = ({ branchId = null, reportIndex, branchIndex }) => {
  const {
    tickets,
    services,
    servicesSubscriptions,
    workWeek,
    organizationId,
    dateRange,
    branches,
  } = useSelector((state) => state.defaultReducer);
  const servicesClone = _.cloneDeep(services);
  const workWeekClone = _.cloneDeep(workWeek);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  let ticketsClone = _.cloneDeep(tickets);
  ticketsClone = ticketsClone.filter(
    (a) => a.organizationId === organizationId
  );
  const branchesClone = _.cloneDeep(branches);

  let updatedServices = checkServiceHasChildren(servicesClone);
  let subscribedServices = [];

  if (branchId) {
    // if branchId is provided, filter tickets and services by branchId
    subscribedServices =
      servicesSubscriptionsClone.find((a) => a.branchId === branchId)
        ?.services || null;
    if (subscribedServices == null) {
      console.log(
        branchId,
        subscribedServices,
        "Branch is not subscribed to any services"
      );
      return null;
    }
    // update services to only subscribed services
    updatedServices = updatedServices.filter((a) =>
      subscribedServices.includes(a.id)
    );
    // update tickets to only subscribed services
    ticketsClone = ticketsClone.filter((a) =>
      subscribedServices.includes(a.serviceId)
    );

    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId
    )?.services;

    subscribedServices = checkServiceHasChildren(servicesClone)
      .filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      )
      .filter((service) => service.id);
  } else {
    //go through all branches and get all unique services]
    const orgBranches = branchesClone.filter(
      (branch) =>
        branch.organizationId === organizationId && Number(branch.reportStatus)
    );
    let orgServices = [];
    orgBranches.forEach((branch) => {
      const branchServices =
        servicesSubscriptionsClone.find(
          (service) => service.branchId === branch.id
        )?.services || [];
      orgServices.push(...branchServices);
    });

    updatedServices = checkServiceHasChildren(servicesClone).filter(
      (service) => orgServices?.includes(service.id) && !service.hasChildren
    );
  }

  // use starting date from date range picker dateRange
  const dateObject = dayjs.unix(dateRange[0]);
  const daysInMonth = dateObject.daysInMonth();
  const datesArray = generateDateArray(dateRange[0], dateRange[1]);

  // create object to hold stats
  const serviceStatsObject = {
    total: 0,
    completed: 0,
    noShow: 0,
    unattended: 0,
    pctCompleted: 0,
    pctNoShow: 0,
    pctUnattended: 0,
    averageTicketsPerDay: 0,
    minTicketsPerDay: 0,
    maxTicketsPerDay: 0,

    days: datesArray.map((fullDate, i) => {
      let day = dayjs(fullDate).date();
      return {
        day,
        total: 0,
        completed: 0,
        noShow: 0,
        unattended: 0,
      };
    }),
  };

  // create array of objects to hold stats for each service

  const managementStats = updatedServices
    .filter((a) => !a.hasChildren)
    .map((a) => ({
      ..._.cloneDeep(serviceStatsObject),
      id: a.id,
      name: a.name,
    }));

  ticketsClone.forEach((a) => {
    const serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
    if (serviceIndex !== -1) {
      // unix timestamp to Date using dayjs, then get the day index in the array with (-1)
      const dayIndex = managementStats[serviceIndex].days.findIndex(
        (b) => b.day === dayjs.unix(a.created).date()
      );
      // Update total tickets per day and total tickets per month
      if (managementStats[serviceIndex].days[dayIndex]) {
        managementStats[serviceIndex].total =
          managementStats[serviceIndex].total + 1;
        managementStats[serviceIndex].days[dayIndex].total =
          managementStats[serviceIndex].days[dayIndex].total + 1;

        if (a.status === completed) {
          managementStats[serviceIndex].completed += 1;
          managementStats[serviceIndex].days[dayIndex].completed += 1;
        } else if (a.status === noShow) {
          managementStats[serviceIndex].noShow += 1;
          managementStats[serviceIndex].days[dayIndex].noShow += 1;
        } else if (a.status < 2) {
          managementStats[serviceIndex].unattended += 1;
          managementStats[serviceIndex].days[dayIndex].unattended += 1;
        }
      }
    }
  });

  // non work days. use workWeek to get non work days
  const nonWorkDays = getNonWorkDays(workWeekClone);
  // get non work dates from the calender month
  const nonWorkDates = getNonWorkDates(dateObject, nonWorkDays, daysInMonth);

  // update pct completed, no show, and unattended
  managementStats.forEach((a) => {
    a.pctCompleted = (a.completed / a.total) * 100 || 0;
    a.pctNoShow = (a.noShow / a.total) * 100 || 0;
    a.pctUnattended = (a.unattended / a.total) * 100 || 0;

    // avg, min, max
    const totalTickets = a.total;
    a.averageTicketsPerDay = (totalTickets / daysInMonth).toFixed(2);
    a.minTicketsPerDay = Math.min(...a.days.map((c) => c.total));
    a.maxTicketsPerDay = Math.max(...a.days.map((c) => c.total));

    // remove non work dates from days array
    a.days = a.days.filter((b) => !nonWorkDates.includes(b.day.toString()));
  });

  // sort by total in descending order, followed by all empty data sets sorted alphabetically
  managementStats.sort((a, b) => {
    // Sort by total in descending order
    if (b.total - a.total !== 0) {
      return b.total - a.total;
    }
    // If total is the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  return (
    <Box>
      <TicketsPerServiceTable
        branchId={branchId}
        data={managementStats}
        subscribedServices={subscribedServices}
        reportIndex={reportIndex}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default TicketsByService;
