import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

// Filters
import FilterForm from '../components/FilterForm';

// Reports
// organization
import Tickets from './organization/Tickets';
import TicketsPerService from './organization/TicketsPerService';
import ServiceTimePerService from './organization/ServiceTimePerService';

import TicketsPerServiceBranches from './branches/TicketsPerService';
import ServiceTimePerServiceBranches from './branches/ServiceTimePerService';
import ArrivalRatesPerHour from './organization/ArrivalRatesPerHour';
import ArrivalRatesPerDay from './organization/ArrivalRatesPerDay';
import ArrivalRatesPerService from './organization/ArrivalRatesPerService';
import TicketsPerBranch from './organization/TicketsPerBranch';
import AgentServiceTimePerService from './branches/AgentServiceTimePerService';
import AgentTicketsByService from './branches/AgentTicketsByService';
import BranchesArrivalRatesPerDay from './branches/ArrivalRatesPerDay';
import BranchesArrivalRatesPerHour from './branches/ArrivalRatesPerHour';
import BranchesArrivalRatesPerService from './branches/ArrivalRatesPerService';
import TicketsPerAgents from './branches/TicketsPerAgents';

const FullReport = () => {
    
    const report = [
        <Tickets />,
        <TicketsPerService />,
        <ServiceTimePerService />,
        <TicketsPerServiceBranches />,
        <ServiceTimePerServiceBranches />,
        <ArrivalRatesPerHour/>,
        <ArrivalRatesPerDay/>,
        <ArrivalRatesPerService/>,
        <TicketsPerBranch/>,
        <AgentServiceTimePerService/>,
        <AgentTicketsByService/>,
        <BranchesArrivalRatesPerDay/>,
        <BranchesArrivalRatesPerHour/>,
        <BranchesArrivalRatesPerService/>,
        <TicketsPerAgents/>
    ]
    return (
        <Box>
            <FilterForm />
            {
                report.map((a, index) => React.cloneElement(a, { key: index, reportIndex: index+1, showFilterForm: 0 }))
            }
        </Box>
    );
};

export default FullReport;
  