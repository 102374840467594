import React from 'react';
import {
    Box, Heading, Text,
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import _ from 'lodash';

import { reportDescriptions } from "../../store/constants";

import FilterForm from '../../components/FilterForm';
import TicketsByService from "../../components/organization/reports/TicketsPerService";
import { getReportDescription } from '../../store/helpers';


const TicketsByServicePage = ({ showFilterForm = true, reportIndex = 1 }) => {
    const { branches, tickets, organizationId, branchId, reportRange } = useSelector((state)=> state.defaultReducer);
    const branchesClone = _.cloneDeep(branches);
    const ticketsClone = _.cloneDeep(tickets);

    const reportTitle = `${reportIndex}. Tickets Per Service`;
    console.log(reportTitle);

    return (
        <Box>
            {
            // create a page break if this is not the first report
            Number(reportIndex) !== 1
                ?   <Box className='forcePageBreak'></Box>
                :   null
            }
            { showFilterForm ? <FilterForm /> : null }
            {
                ticketsClone.length
                ?   <Box mb="20px">
                        <h1>{reportTitle}</h1>
                        <p className="reportDescription">{getReportDescription(reportRange).TicketsPerServiceBranches}</p>
                    </Box>
                :   null
            }
            { 
                ticketsClone.length
                ?   branchesClone
                    .filter( (a) => 
                        a.organizationId === organizationId
                        && (!branchId.length || ( branchId.length && branchId === a.id))
                        && a.reportStatus != null
                        && Number(a.reportStatus) 
                    )
                    .map( (a, index) => <TicketsByService key={index} branchId= {a.id} reportIndex={reportIndex} branchIndex={index+1} />) 
                :   null
            }
        </Box>
    );
};

export default TicketsByServicePage;
  