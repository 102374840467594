import { connect } from "react-redux";
import React, { useEffect } from "react";
import _ from "lodash";

import { Box, Heading } from "@chakra-ui/react";

import FilterForm from "../components/FilterForm";
import ServicesTable from "../components/organization/ServicesTable";

import { useSelector } from "react-redux";
import { formatNumber } from "../store/helpers";

const Dashboard = () => {
  const { tickets, services } = useSelector((state) => state.defaultReducer);
  // find tickets whose services were deleted
  const ticketsClone = _.cloneDeep(tickets);
  let servicesClone = _.cloneDeep(services);

  const ticketsUpdated = ticketsClone.map((a, i) => {
    a.serviceExistStatus = servicesClone.find((b) => b.id === a.serviceId)
      ? 1
      : 0;
    return a;
  });

  const missingServices = ticketsUpdated
    .map((a) => {
      if (!a.serviceExistStatus) return a;
    })
    .filter(Boolean);

  // missing serviceIds
  const missingServiceIds = missingServices.map((a) => a.serviceId);

  // unique ids only
  const uniqueMissingServiceIds = [...new Set(missingServiceIds)];

  // create service for missing services
  const missingServicesCreated = uniqueMissingServiceIds.map((a) => {
    const ticket = missingServices.find((b) => b.serviceId === a);
    return {
      id: a,
      name: missingServices.serviceName,
      parent: 0,
    };
  });

  let servicesComplete = [...servicesClone, ...missingServicesCreated];
  // add has children
  servicesComplete = servicesComplete.map((a) => {
    return {
      ...a,
      hasChildren: servicesComplete.filter((b) => b.parent === a.id).length
        ? 1
        : 0,
    };
  });

  return (
    <Box>
      <Heading size="md">Welcome to the QM Analytics Dashboard</Heading>
      <FilterForm />
      <Box mt="20px">Results: {formatNumber(ticketsClone.length) } tickets</Box>
    </Box>
  );
};

export default Dashboard;
