import _ from "lodash";

import { Box } from "@chakra-ui/react";

import FilterForm from "../../components/FilterForm";
import { useSelector } from "react-redux";

import {
  checkServiceHasChildren,
  getReportDescription,
} from "../../store/helpers";
import ArrivalRatesByServiceReport from "../../components/organization/reports/ArrivalRatesByService";
import Loader from "../../components/loader";

const ArrivalRatesPerService = ({ showFilterForm = true, reportIndex = 1 }) => {
  const {
    tickets,
    organizationId,
    services,
    loading,
    reportRange,
    branches,
    servicesSubscriptions,
  } = useSelector((state) => state.defaultReducer);
  const ticketsClone = _.cloneDeep(tickets);
  let servicesClone = _.cloneDeep(services);
  const branchesClone = _.cloneDeep(branches);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);

  const orgBranches = branchesClone.filter(
    (branch) =>
      branch.organizationId === organizationId && Number(branch.reportStatus)
  );
  let orgServices = [];
  orgBranches.forEach((branch) => {
    const branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branch.id
    )?.services || [];
    orgServices.push(...branchServices);
  });

  let updatedServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => orgServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);


  const servicesMap = updatedServices.reduce((acc, serviceId) => {
    acc[serviceId] = [];
    return acc;
  }, {});

  // Iterate over the data array and group objects by serviceId
  ticketsClone.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const title = `${reportIndex}. Arrival Rates Per Service`;
  console.log(title);

  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <Box mt={10}>
                <h1>{title}</h1>
                <p>{getReportDescription(reportRange).ArrivalRatesByService}</p>
                <ArrivalRatesByServiceReport
                  servicesMap={servicesMap}
                  reportIndex={reportIndex}
                />
              </Box>
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerService;
