import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

// Filters
import FilterForm from '../components/FilterForm';

// Reports
import BranchesArrivalRatesPerDay from './branches/ArrivalRatesPerDay';
import BranchesArrivalRatesPerHour from './branches/ArrivalRatesPerHour';
import BranchesArrivalRatesPerService from './branches/ArrivalRatesPerService';

const FullReport = () => {
    
    const report = [
        <BranchesArrivalRatesPerHour/>,
        <BranchesArrivalRatesPerDay/>,
        <BranchesArrivalRatesPerService/>,
    ]
    return (
        <Box>
            <FilterForm />
            {
                report.map((a, index) => React.cloneElement(a, { key: index, reportIndex: index+1, showFilterForm: 0 }))
            }
        </Box>
    );
};

export default FullReport;
  