import dayjs from "dayjs";
import _ from "lodash";
import { useSelector } from "react-redux";
import ServiceArrivalRatesTable from "../../organization/ServiceArrivalRatesTable";

const ArrivalRatesByService = ({ servicesMap, reportIndex }) => {
  const { services, tickets } = useSelector((store) => store.defaultReducer);
  const servicesClone = _.cloneDeep(services);
  const ticketsClone = _.cloneDeep(tickets);

  const servicesTablesData = Object.keys(servicesMap)
    .map((key) => {
      const arrivalTimes = servicesMap[key].map((entry) =>
        dayjs.unix(entry.created)
      );

      const serviceName = servicesClone.find(
        (service) => service.id === key
      )?.name;
      const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
        const formattedDate = arrivalTime.format("YYYY-MM-DD");

        acc[formattedDate] = (acc[formattedDate] || 0) + 1;

        return acc;
      }, {});

      const rates = Object.entries(arrivalTimesPerDay).map(([date, count]) => ({
        date,
        count,
      }));
      rates.sort((a, b) => new Date(a.date) - new Date(b.date));

      const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
      const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
      const total = rates.reduce((sum, entry) => sum + entry.count, 0);
      const average = rates.length ? total / rates.length : 0;
      const totalPct = (total / ticketsClone.length) * 100;

      const hasData = Object.keys(arrivalTimesPerDay).length;
      const heading = hasData
        ? `${serviceName}`
        : `No Data for Service: ${serviceName}`;

      return {
        arrivalTimesPerDay,
        serviceName,
        min,
        max,
        total,
        average,
        totalPct,
        heading,
        hasData,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets time in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.serviceName.localeCompare(b.serviceName);
    });

  //More accurate than ticketsClone.length, some tickets may belong to deleted services
  const totalTickets = servicesTablesData
    .map((a) => a.total)
    .reduce((acc, val) => {
      acc += val;
      return acc;
    }, 0);

  return (
    <>
      {servicesTablesData.map((serviceData, index) => {
        return (
          <>
            <ServiceArrivalRatesTable
              totalTickets={totalTickets}
              index={index}
              reportIndex={reportIndex}
              serviceData={serviceData}
            />
          </>
        );
      })}
    </>
  );
};

export default ArrivalRatesByService;
