import { Box } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import TicketsPerBranchTable from "../../tables/daily/TicketsPerBranchTable";

const OrganizationTicketsPerBranch = ({ reportIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
  } = useSelector((state) => state.defaultReducer);
  let branchesClone = _.cloneDeep(branches);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  branchesClone = branchesClone.filter(
    (a) => a.reportStatus != null && Number(a.reportStatus)
  );

  const branchesMap = branchesClone.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  ticketsClone.forEach((data) => {
    const branchId = data.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(data);
    }
  });

  const totalOrgTickets = tickets.length;

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchTickets = branchesMap[branchId];
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;
      branchServices = checkServiceHasChildren(servicesClone).filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      );
      const numServices = branchServices.length || 0;

      //BranchChartData

      ///const
      const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
      const chart = hoursArray
        .map((hour) => {
          const hourTickets = branchTickets.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
            };
          return null;
        })
        .filter(Boolean);

      const min = Math.min(...chart.map((a) => a.total)) || 0;
      const max = Math.max(...chart.map((a) => a.total)) || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const average =
        chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
      let totalPct = (total / totalOrgTickets) * 100 || 0;

      return {
        branchName,
        min,
        branchId,
        max,
        total,
        average,
        totalPct,
        numServices,
        chart,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets time in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.branchName.localeCompare(b.branchName);
    });

  console.log(branchesData);

  return (
    <Box>
      {branchesData.map((branchData, index) => {
        return (
          <Box className="reportContainer" mt="20px">
            <TicketsPerBranchTable
              index={index}
              branchData={branchData}
              reportIndex={reportIndex}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default OrganizationTicketsPerBranch;
