import {
  Box,
  Center,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import ArrivalRatesLineGraph from "../../ArrivalRatesLineGraph";
import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../../store/constants";
import ArrivalHeatmap from "../../ArrivalHeatmap";
import { useSelector } from "react-redux";
import {
  getReadableKeyFromObject,
  formatNumber,
  checkServiceHasChildren,
} from "../../../store/helpers";
import { useEffect, useState } from "react";
import _ from "lodash";

const BranchesTableRow = ({ data, frequency, branchId, index, branchName }) => {
  const { branches, hoursPerDay } = useSelector(
    (state) => state.defaultReducer
  );
  const arrivalTimes = data.map((entry) => dayjs.unix(entry.created));

  const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
    const formattedDate = arrivalTime.format("YYYY-MM-DD");

    acc[formattedDate] = (acc[formattedDate] || 0) + 1;

    return acc;
  }, {});

  const rates = Object.entries(arrivalTimesPerDay).map(([date, count]) => ({
    date,
    count:
      frequency === ARRIVAL_RATES_FREQUENCIES.HOUR
        ? count / hoursPerDay
        : count,
  }));
  rates.sort((a, b) => new Date(a.date) - new Date(b.date));

  const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
  const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
  const total = rates.reduce((sum, entry) => sum + entry.count, 0) || 0;
  const average = rates.length ? total / rates.length : 0;

  return (
    <>
      <Tr>
        <Td>{index + 1}</Td>
        <Td borderRight="1px solid #dadce0">{formatNumber(total)}</Td>
        <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
        <Td>{formatNumber(min)}</Td>
        <Td>{formatNumber(max)}</Td>
      </Tr>
      <Tr>
        <Td colSpan={5}>
          <Box mt="20px" mb="20px">
            <ArrivalRatesLineGraph
              arrivalTimesPerDay={arrivalTimesPerDay}
              frequency={frequency}
              level={LEVELS.BRANCH}
              title={`${branchName} Arrival rates per ${getReadableKeyFromObject(
                ARRIVAL_RATES_FREQUENCIES,
                frequency
              )}, Day by Day Comparison`}
            />
          </Box>
          {frequency === ARRIVAL_RATES_FREQUENCIES.HOUR ? (
            <ArrivalHeatmap arrivalTimes={arrivalTimes} />
          ) : (
            <></>
          )}
        </Td>
      </Tr>
    </>
  );
};

const BranchesTable = ({ data, frequency, reportIndex }) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);

  const { branches, servicesSubscriptions, services } = useSelector(
    (store) => store.defaultReducer
  );
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  return (
    <>
      <Box>
        {Object.keys(data).map((branchId, index) => {
          const branchName =
            branches.find((branch) => branch.id === branchId)?.name ||
            `Branch-${branchId}`;

          let branchServices = servicesSubscriptionsClone.find(
            (service) => service.branchId === branchId
          )?.services;

          branchServices = checkServiceHasChildren(servicesClone).filter(
            (service) =>
              branchServices?.includes(service.id) && !service.hasChildren
          );
          const title = `${reportIndex || ""}.${index + 1}. ${branchName}`;
          console.log(title);
          return (
            <>
              <h2>{title}</h2>
              <Text>{branchServices?.length} services</Text>
              {data[branchId].length ? (
                <Table className="stripedTable" size="sm">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th borderRight="1px solid #dadce0">Total Visitors</Th>
                      <Th borderRight="1px solid #dadce0">Average</Th>
                      <Th>Min</Th>
                      <Th>Max</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <BranchesTableRow
                      data={data[branchId]}
                      frequency={frequency}
                      branchId={branchId}
                      index={index}
                      branchName={branchName}
                    />
                  </Tbody>
                </Table>
              ) : (
                <>
                  {" "}
                  <Text fontSize="sm">No data available for this report</Text>
                </>
              )}
            </>
          );
        })}
      </Box>
    </>
  );
};

export default BranchesTable;
