import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Select, Text } from "@chakra-ui/react";
import { updateBranchId } from "../../store/actions/defaultAction";

const BranchSelection = () => {
  const { branches, organizationId, branchId } = useSelector(
    (store) => store.defaultReducer
  );
  const orgBranches = branches.filter(
    (branch) => branch.organizationId === organizationId
  );

  const dispatch = useDispatch();
  return (
    <Box>
      <Text fontSize={14}>Select Branch</Text>
      <Select
        placeholder="Select Branch"
        value={branchId}
        onChange={(e) =>
          dispatch(updateBranchId({ branchId: e.target.value, organizationId }))
        }
      >
        {
        orgBranches
        .filter( a =>
          a.reportStatus != null
          && Number(a.reportStatus) 
        )
        .map((a, i) => (
          <option key={i} value={a.id}>
            {a.name}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default BranchSelection;
