import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { generateHoursArray } from "../../../../store/helpers";
import ArrivalRatesPerServiceTable from "../../tables/daily/ArrivalRatesPerServiceTable";
import { Box } from "@chakra-ui/react";

const BranchArrivalRatesPerService = ({
  branchId,
  reportIndex,
  branchIndex,
}) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);

  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  let branchesClone = _.cloneDeep(branches);

  const branchData = ticketsClone.filter((a) => a.branchId === branchId);
  const branchName =
    branchesClone.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;
  const branchServices =
    servicesSubscriptionsClone.find((service) => service.branchId === branchId)
      ?.services || [];

  const servicesMap = {};
  branchServices.forEach((serviceId) => {
    servicesMap[serviceId] = [];
  });

  // Iterate over the data array and group objects by serviceId
  branchData.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const servicesData = Object.keys(servicesMap).map((serviceId) => {
    const serviceData = servicesMap[serviceId];
    const serviceName =
      servicesClone.find((service) => serviceId === service.id)?.name ||
      "Unnamed";

    const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
    const chart = hoursArray
      .map((hour) => {
        const hourTickets = serviceData.filter(
          (a) => dayjs.unix(a.created).format("h A") === hour
        );

        if (workDay.includes(hour))
          return {
            hour,
            total: hourTickets.length,
          };
        return null;
      })
      .filter(Boolean);

    const total = chart.reduce((a, b) => a + b.total, 0) || 0;
    const average = chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
    const min = Math.min(...chart.map((a) => a.total)) || 0;
    const max = Math.max(...chart.map((a) => a.total)) || 0;

    return {
      min,
      max,
      total,
      average,
      serviceName,
      serviceId,
      chart,
      branchName,
    };
  });

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);
  return (
    <Box>
      <h2>{title}</h2>
      {servicesData.map((data, index) => {
        return (
          <ArrivalRatesPerServiceTable
            index={index}
            data={data}
            branchIndex={branchIndex}
            reportIndex={reportIndex}
            branchName={branchName}
          />
        );
      })}
    </Box>
  );
};

export default BranchArrivalRatesPerService;
