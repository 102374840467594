import _ from "lodash";

import { Box } from "@chakra-ui/react";

import FilterForm from "../../components/FilterForm";
import { useSelector } from "react-redux";
import BranchesTable from "../../components/branches/tables/BranchesTable";
import { ARRIVAL_RATES_FREQUENCIES } from "../../store/constants";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";

const ArrivalRatesPerHour = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { tickets, branches, organizationId, loading, reportRange, branchId } =
    useSelector((state) => state.defaultReducer);
  const ticketsClone = _.cloneDeep(tickets);
  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (branch) =>
        branch.organizationId === organizationId && Number(branch.reportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));

  //separate the data into branches
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  ticketsClone.forEach((data) => {
    const branchId = data.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(data);
    }
  });

  const title = `${reportIndex}. Branch Arrival Rates Per Hour`;
  console.log(title);

  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{title}</h1>
              <p>
                {getReportDescription(reportRange).BranchesArrivalRatesPerHour}
              </p>
              <BranchesTable
                reportIndex={reportIndex}
                data={branchesMap}
                frequency={ARRIVAL_RATES_FREQUENCIES.HOUR}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerHour;
