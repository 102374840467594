import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";
import { REPORT_RANGES } from "../constants";

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=not done yet, 0=failed, 1=success
  reportRange: REPORT_RANGES.MONTH,

  organizations: [],
  tickets: [],
  services: [],
  servicesSubscriptions: [],
  users: [],
  branches: [],

  // filter
  organizationId: "",
  branchId: "",
  dateRange: [
    dayjs().subtract(1, "month").startOf("month").unix(),
    dayjs().subtract(1, "month").endOf("month").unix(),
  ],

  // settings
  hoursPerDay: 8,
  loading: false,
  workWeek: [1, 2, 3, 4, 5], // sunday=0, monday=1, tuesday=2, wednesday=3, thursday=4, friday=5, saturday=6
  workDay: [
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
  ],
  showEmptyDataSets: 0, // 1=show , 0=hide,
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        tickets: action.payload,
        loading: false,
      };
    case "GET_SERVICES":
      return {
        ...state,
        services: action.payload,
      };
    case "GET_SERVICES_SUBSCRIPTIONS":
      return {
        ...state,
        servicesSubscriptions: action.payload,
      };
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "GET_BRANCHES":
      return {
        ...state,
        branches: action.payload,
      };

    case "SET_DATE_RANGE":
      return {
        ...state,
        dateRange: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };
    case "UPDATE_REPORT_RANGE":
      return {
        ...state,
        reportRange: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_DURATION:
      return {
        ...state,
        reportDuration: action.payload,
      };

    case "GET_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.payload,
      };

    case "UPDATE_ORGANIZATION_ID":
      return {
        ...state,
        organizationId: action.payload,
      };

    case "UPDATE_BRANCH_ID":
      return {
        ...state,
        branchId: action.payload,
      };

    default:
      return state;
  }
}
