import { connect } from "react-redux";
import React, { useState } from 'react';
import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Button,
    Stack,
    Badge,
    Text,
    extendTheme,
    Select,
    HStack,
    VStack,
    Flex,

} from '@chakra-ui/react';
import _ from 'lodash';

const ServicesTable = (props) => {

  const [sortingType, setSortingType] = useState(0);
  const [hideEmptyServices, setHideEmptyServices] = useState(0);

  // deep clone using lodash
  const servicesClone = _.cloneDeep(props.services);
  const ticketsClone = _.cloneDeep(props.tickets);

  // sort by name
  // servicesClone.sort((a, b) => a.name.localeCompare(b.name));
  let emptyServices = [];
  let updatedServices = servicesClone
  .filter( a => !a.hasChildren )
  .map( (a) => {
    a.total = ticketsClone.filter( b => b.serviceId === a.id).length;
    a.completed = ticketsClone.filter( b => b.serviceId === a.id && Number(b.status) === 3 ).length;
    a.noShow = ticketsClone.filter( b => b.serviceId === a.id && Number(b.status) === 2 ).length;
    a.Unattended = ticketsClone.filter( b => b.serviceId === a.id && Number(b.status) < 2 ).length;

    a.pctCompleted = (a.completed / a.total) * 100 || 0;
    a.pctNoShow = (a.noShow / a.total) * 100 || 0;
    a.pctUnattended = (a.Unattended / a.total) * 100 || 0;

    if (Number(hideEmptyServices) && Number(a.total) === 0) emptyServices.push(a);

    return a;
  })
  .filter( a => { 
    return a.total > 0
  })
  .filter(Boolean)
  .sort((a, b) => b.total - a.total);

  if(Number(hideEmptyServices)) emptyServices = emptyServices.sort((a, b) => a.name.localeCompare(b.name));

  updatedServices = [...updatedServices, ...emptyServices];  

  const tableRows = updatedServices.map( (a, index) => {
    return (
      <Tr key={index}>
        <Td>{index+1}</Td>
        <Td borderRight="1px solid #dadce0" color={a.total === 0 ? 'var(--red)' : ''}>
          {a.name ? a.name : <Text color={'var(--red)'}>Deleted</Text>}
        </Td>
        <Td>
        <Text color={'var(--blue)'}>{a.total}</Text>
        </Td>
        <Td>
          <Text color={'var(--green)'}>
            {a.completed}
            <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctCompleted.toFixed(2)}%)</Box>
          </Text>
        </Td>
        <Td>
          <Text cursor="pointer" color={'var(--red)'}>
            {a.noShow} 
            <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctNoShow.toFixed(2)}%)</Box>
          </Text>
        </Td>
        <Td>
          <Text color={'var(--red)'}>
            {a.Unattended} 
            <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctUnattended.toFixed(2)}%)</Box>
          </Text>
        </Td>
      </Tr>
    )
  });
    
  return (
    <Box mt={10}>
      <Heading size='md' mb="20px">Organization Services</Heading>
      <Flex justifyContent='flex-end' alignContent='center' mt='20px' mb='8px' h='40px' borderTop='1px solid #dadce0' borderBottom='1px solid #dadce0'>
        {/* filter and sorting options */}
        <HStack spacing={4}>
          <HStack>
            <Text fontSize='sm' fontWeight='semibold' whiteSpace="nowrap">Filter by:</Text>
            {/* set default */}
            <Select value={sortingType} onChange={ (e) => setSortingType(e.target.value) } size='sm'>
              <option value={0}>Default</option>
              <option value={1}>Option 2</option>
            </Select>
          </HStack>
          <HStack>
            <Text fontSize='sm' fontWeight='semibold' whiteSpace="nowrap">Empty Services:</Text>
            {/* set default */}
            <Select value={hideEmptyServices} onChange={ (e) => setHideEmptyServices(e.target.value) } size='sm'>
              <option value={0}>Hide</option>
              <option value={1}>Show</option>
            </Select>
          </HStack>
        </HStack>

      </Flex>
      <Box>
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Service</Th>
              <Th>Total Tickets</Th>
              <Th>Completed</Th>
              <Th>No Show</Th>
              <Th>Unattended</Th>
            </Tr>
          </Thead>
          <Tbody>
            { tableRows }
          </Tbody>
          
        </Table>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
    const { defaultReducer } = state
    return {
      tickets: defaultReducer.tickets,
    };
};
  
export default connect(mapStateToProps, {})(
    ServicesTable
);
  