/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AverlyLogo from "../../assets/media/logos/averly.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authenticateUser } from "../../store/actions/authActions";

const Login = () => {
  const { authLoading } = useSelector((store) => store.authReducer);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onLogin = async (data) => {
    const { email, password } = data;
    if (!email?.length || !password?.length) {
      toast.error("Email & Password are required");
      return;
    }

    dispatch(authenticateUser({ email, password }));
  };
  return (
    <>
      <Stack direction={{ base: "column", md: "row" }}>
        <Flex flex={1} p={8} justify="center">
          <Stack spacing={2} w="full" maxW="lg">
            <Stack align="center">
              <Img src={AverlyLogo} boxSize="8rem" />
            </Stack>
            <Stack>
              <Text textAlign="center">
                Welcome back! Please enter your details
              </Text>
            </Stack>
            <Stack spacing="8">
              <Stack as="form" onSubmit={handleSubmit(onLogin)}>
                <FormControl>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    placeholder="name@example.com"
                    focusBorderColor="#4C2DB1"
                    _placeholder={{
                      fontSize: "16px",
                      color: "#666666",
                      textAlign: "start",
                    }}
                    py="1.5em"
                    {...register("email", {
                      required: true,
                      maxLength: 100,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    type="email"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <Text as="span" color="red" fontSize="15px">
                      user email is required
                    </Text>
                  )}
                  {errors.email && (
                    <Text as="span" color="red" fontSize="15px">
                      {errors.email.message}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      focusBorderColor="#4C2DB1"
                      type={show ? "text" : "password"}
                      placeholder="Enter Password"
                      _placeholder={{
                        fontSize: "16px",
                        color: "#666666",
                        textAlign: "start",
                      }}
                      py="1em"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    <InputRightElement>
                      <Text
                        h="1.75rem"
                        mr="3rem"
                        mt="0.5em"
                        size="sm"
                        color="#666666"
                        cursor="pointer"
                        onClick={() => {
                          setShow((show) => !show);
                        }}
                      >
                        {show ? "Hide" : "Show"}
                      </Text>
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && errors.password.type === "required" && (
                    <Text as="span" color="red" fontSize="15px">
                      user password is required
                    </Text>
                  )}
                </FormControl>
                <Button
                  py="1.5em"
                  bg="#EEEBFA"
                  color="#BCAEEA"
                  _hover={{ bg: "var(--blue)", color: "#ffffff" }}
                  _active={{ bg: "var(--blue)", color: "#fffff" }}
                  type="submit"
                  isLoading={authLoading}
                  loadingText="logging in"
                >
                  Log In
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};
export default Login;
