import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { TICKET_STATUS } from "../../../../store/constants";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import OrganizationTicketsPerServiceTable from "../../../organization/tables/daily/OrganizationTicketsPerService";
import { Box } from "@chakra-ui/react";

const BranchTicketsPerService = ({ branchId, reportIndex, branchIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);
  let ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  let branchesClone = _.cloneDeep(branches);
  ticketsClone = ticketsClone.filter((a) => a.branchId === branchId);

  let updatedServices = checkServiceHasChildren(servicesClone);
  let subscribedServices = [];
  const branchName =
    branchesClone.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  if (branchId) {
    subscribedServices =
      servicesSubscriptionsClone.find((a) => a.branchId === branchId)
        ?.services || [];
    if (subscribedServices == []) {
      console.log(
        branchId,
        subscribedServices,
        "Branch is not subscribed to any services"
      );
      return [];
    }
    // update services to only subscribed services
    updatedServices = updatedServices.filter((a) =>
      subscribedServices.includes(a.id)
    );
    let branchServices = servicesSubscriptionsClone.find(
      (service) => service.branchId === branchId
    )?.services;

    subscribedServices = checkServiceHasChildren(servicesClone)
      .filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      )
      .filter((service) => service.id);
  }

  const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
  const serviceStatsObject = {
    total: 0,
    completed: 0,
    noShow: 0,
    unattended: 0,
    pctCompleted: 0,
    pctNoShow: 0,
    pctUnattended: 0,
    averageTicketsPerHour: 0,
    minTicketsPerHour: 0,
    maxTicketsPerHour: 0,

    hours: hoursArray
      .map((hour, i) => {
        if (workDay.includes(hour))
          return {
            hour,
            total: 0,
            completed: 0,
            noShow: 0,
            unattended: 0,
          };

        return null;
      })
      .filter(Boolean),
  };

  // create array of objects to hold stats for each service

  const managementStats = updatedServices
    .filter((a) => !a.hasChildren)
    .map((a) => ({
      ..._.cloneDeep(serviceStatsObject),
      id: a.id,
      name: a.name,
    }));

  ticketsClone.forEach((a) => {
    const serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
    if (serviceIndex !== -1) {
      const hourIndex = managementStats[serviceIndex].hours.findIndex(
        (b) => b.hour === dayjs.unix(a.created).format("h A")
      );
      // Update total tickets per day and total tickets per month
      if (managementStats[serviceIndex].hours[hourIndex]) {
        managementStats[serviceIndex].total =
          managementStats[serviceIndex].total + 1;
        managementStats[serviceIndex].hours[hourIndex].total =
          managementStats[serviceIndex].hours[hourIndex].total + 1;

        if (a.status === TICKET_STATUS.COMPLETED) {
          managementStats[serviceIndex].completed += 1;
          managementStats[serviceIndex].hours[hourIndex].completed += 1;
        } else if (a.status === TICKET_STATUS.NO_SHOW) {
          managementStats[serviceIndex].noShow += 1;
          managementStats[serviceIndex].hours[hourIndex].noShow += 1;
        } else if (a.status < 2) {
          managementStats[serviceIndex].unattended += 1;
          managementStats[serviceIndex].hours[hourIndex].unattended += 1;
        }
      }
    }
  });

  // update pct completed, no show, and unattended
  managementStats.forEach((a) => {
    a.pctCompleted = (a.completed / a.total) * 100 || 0;
    a.pctNoShow = (a.noShow / a.total) * 100 || 0;
    a.pctUnattended = (a.unattended / a.total) * 100 || 0;

    // avg, min, max
    const totalTickets = a.total;
    a.averageTicketsPerHour = (
      totalTickets / serviceStatsObject.hours.length
    ).toFixed(2);

    let minTicketsPerHour = Math.min(...a.hours.map((c) => c.total));
    let maxTicketsPerHour = Math.max(...a.hours.map((c) => c.total));

    a.minTicketsPerHour = isFinite(minTicketsPerHour) ? minTicketsPerHour : 0;
    a.maxTicketsPerHour = isFinite(maxTicketsPerHour) ? maxTicketsPerHour : 0;
  });

  const data = {
    serviceData: managementStats,
    branchId,
    branchName,
    numServices: subscribedServices.length,
  };

  return (
    <Box>
      <OrganizationTicketsPerServiceTable
        branchId={branchId}
        data={data.serviceData}
        numServices={subscribedServices.length}
        branchName={branchName}
        reportIndex={reportIndex}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default BranchTicketsPerService;
