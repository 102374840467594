import {
  Box,
  Center,
  Heading,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mapHasData, formatNumber } from "../../../store/helpers";

const AgentServicesTableRows = ({ data, totalServiceData }) => {
  return (
    <>
      {data.map((agentData, index) => {
        const { total } = agentData;
        let totalPct = (total / totalServiceData) * 100 || 0;
        return (
          <Tr>
            <Td>{index + 1}</Td>
            <Td>{agentData.agentName}</Td>
            <Td borderRight="1px solid #dadce0">
              {" "}
              <Text color={"var(--green)"}>
                {formatNumber(agentData.total)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({totalPct.toFixed(2)}%)
                </Box>
              </Text>
            </Td>
            <Td borderRight="1px solid #dadce0">
              {formatNumber(agentData.average)}
            </Td>
            <Td>{formatNumber(agentData.min)}</Td>
            <Td>{formatNumber(agentData.max)}</Td>
            <Td>
              {" "}
              <Text color={"var(--green)"}>
                {formatNumber(agentData.numCompleted)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctCompleted.toFixed(2)}%)
                </Box>
              </Text>
            </Td>

            <Td>
              {" "}
              <Text color={"var(--red)"}>
                {formatNumber(agentData.numNoShow)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctNoShow.toFixed(2)}%)
                </Box>
              </Text>
            </Td>

            <Td>
              {" "}
              <Text color={"var(--red)"}>
                {formatNumber(agentData.numUnattended)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctUnattended.toFixed(2)}%)
                </Box>
              </Text>
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
const AgentServicesTable = ({
  serviceData,
  serviceId,
  branchId,
  branchName,
  reportIndex,
  branchIndex,
  index,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);

  const { users, services, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const serviceName =
    services.find((service) => serviceId === service.id)?.name || "Unnamed";

  //split the services into agents.
  const usersClone = _.cloneDeep(users);
  const agents = usersClone.filter(
    (user) =>
      user.organizationId === organizationId &&
      user.branchId === branchId &&
      user.services.includes(serviceId)
  );

  const agentsMap = {};
  agents.forEach((agent) => {
    agentsMap[agent.id] = [];
  });

  // Iterate over the data array and group objects by agentsId

  serviceData.forEach((item) => {
    const agentId = item.manage?.userId;

    if (Array.isArray(agentsMap[agentId])) {
      agentsMap[agentId].push(item);
    }
  });

  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${serviceName}`;
  console.log(title);

  ////////Compute rows data
  const rowsData = Object.keys(agentsMap)
    .map((agentId, index) => {
      const data = agentsMap[agentId];
      const arrivalTimes = data.map((entry) => dayjs.unix(entry.created));

      const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
        const formattedDate = arrivalTime.format("YYYY-MM-DD");

        acc[formattedDate] = (acc[formattedDate] || 0) + 1;

        return acc;
      }, {});

      const rates = Object.entries(arrivalTimesPerDay)
        .map(([date, count]) => ({
          date,
          count,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
      const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
      const total = rates.reduce((sum, entry) => sum + entry.count, 0);
      const average = rates.length ? total / rates.length : 0;

      const user = users.find((user) => user.id === agentId);

      //Completed
      const numCompleted =
        data.filter((b) => Number(b.status) === 3).length || 0;

      const numNoShow = data.filter((b) => Number(b.status) === 2).length || 0;

      const numUnattended =
        data.filter((b) => Number(b.status) < 2).length || 0;
      const totalAgentTickets = numCompleted + numUnattended + numNoShow;

      const pctCompleted = (numCompleted / totalAgentTickets) * 100 || 0;
      const pctNoShow = (numNoShow / totalAgentTickets) * 100 || 0;
      const pctUnattended = (numUnattended / totalAgentTickets) * 100 || 0;
      const agentName = `${user.name} ${user.lastname}`;

      return {
        min,
        max,
        total,
        average,
        numCompleted,
        numNoShow,
        numUnattended,
        totalAgentTickets,
        pctCompleted,
        pctNoShow,
        pctUnattended,
        agentName,
      };
    })
    .sort((a, b) => {
      // Sort by total tickets in descending order
      if (b.total - a.total !== 0) {
          return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.agentName.localeCompare(b.agentName);
  });

  //more accurate than serviceData.length
  const totalServiceData = rowsData
    .map((a) => a.total)
    .reduce((acc, val) => {
      acc += val;
      return acc;
    }, 0);

  return (
    <Box>
      <h3>
        {title}
      </h3>
      {!mapHasData(agentsMap) && (
        <Text fontSize="sm">No data available for this report</Text>
      )}
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>

      {mapHasData(agentsMap) && (
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Agent Name</Th>
              <Th borderRight="1px solid #dadce0">Total</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
              <Th>Completed</Th>
              <Th>No Show</Th>
              <Th>Unattended</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              <AgentServicesTableRows
                totalServiceData={totalServiceData}
                data={rowsData}
              />
            }
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default AgentServicesTable;
