import axios from "axios";
import { ACTION_TYPES } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_STATUSES } from "../constants";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

export const authenticateUser =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: true });
    try {
      await axios.post("/auth/authenticate", {
        email,
        password,
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_APP_STATUS,
        payload: APP_STATUSES.LOGGED_IN,
      });
    } catch (err) {
      toast.error("Invalid Email or Password");
    }
    dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: false });
  };

export const updateAppStatus = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_APP_STATUS,
    payload,
  });
};
