import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { formatNumber } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";

const TicketsPerBranchTable = ({ branchData, index, reportIndex }) => {
  const { branchName, min, max, total, average, totalPct, numServices, chart } =
    branchData;

  const title = `${reportIndex || ""}.${index + 1}. ${branchName} Branch`;
  console.log(title);
  return (
    <Box>
      <h2>{title}</h2>
      <Text>{numServices} services</Text>

      {total ? (
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Tickets</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{index + 1}</Td>
              <Td borderRight="1px solid #dadce0">
                {" "}
                <Text color={"var(--green)"}>
                  {formatNumber(total)}
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    ({totalPct.toFixed(2)}%)
                  </Box>
                </Text>
              </Td>

              <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
              <Td>{formatNumber(min)}</Td>
              <Td>{formatNumber(max)}</Td>
            </Tr>

            <Tr>
              <Td colSpan={5}>
                <Box mt="20px" mb="20px">
                  <LineGraph
                    chart={chart}
                    title={`${branchName} tickets, Hour by Hour Comparison`}
                    height={"300px"}
                  />
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default TicketsPerBranchTable;
