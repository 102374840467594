import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import TicketsByBranchTable from "../tables/TicketsByBranchTable";
import dayjs from "dayjs";
import { checkServiceHasChildren } from "../../../store/helpers";

const TicketsByBranch = ({ branchesMap, reportIndex }) => {
  const { tickets, branches, servicesSubscriptions, services } = useSelector(
    (state) => state.defaultReducer
  );
  const ticketsClone = _.cloneDeep(tickets);
  const servicesClone = _.cloneDeep(services);

  const totalOrgTickets = ticketsClone.length;
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchTickets = branchesMap[branchId];
      const branchName =
        branches.find((branch) => branch.id === branchId)?.name ||
        `Branch-${branchId}`;

      const arrivalTimes = branchTickets.map((entry) =>
        dayjs.unix(entry.created)
      );

      const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
        const formattedDate = arrivalTime.format("YYYY-MM-DD");

        acc[formattedDate] = (acc[formattedDate] || 0) + 1;

        return acc;
      }, {});

      const rates = Object.entries(arrivalTimesPerDay)
        .map(([date, count]) => ({
          date,
          count,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
      const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
      const total = rates.reduce((sum, entry) => sum + entry.count, 0);
      const average = rates.length > 0 ? total / rates.length : 0;
      let totalPct = (total / totalOrgTickets) * 100;

      let branchServices = servicesSubscriptionsClone.find(
        (service) => service.branchId === branchId
      )?.services;

      branchServices = checkServiceHasChildren(servicesClone).filter(
        (service) =>
          branchServices?.includes(service.id) && !service.hasChildren
      );
      const numServices = branchServices.length;
      return {
        branchTickets,
        branchName,
        min,
        max,
        total,
        average,
        totalPct,
        arrivalTimesPerDay,
        numServices,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets time in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.branchName.localeCompare(b.branchName);
    });

  return (
    <Box>
      {branchesData.map((branchData, index) => {
        return (
          <>
            <Box className="reportContainer" mt="20px">
              <TicketsByBranchTable
                index={index}
                branchData={branchData}
                reportIndex={reportIndex}
              />
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default TicketsByBranch;
