import {
  Box,
  Center,
  Heading,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../../store/constants";
import dayjs from "dayjs";
import AgentTicketStatusGraphs from "../charts/AgentTicketStatusGraphs";
import _ from "lodash";
import { mapHasData, formatNumber } from "../../../store/helpers";

const AgentTables = ({
  data,
  totalBranchTickets,
  branchName,
  reportIndex,
  branchIndex,
}) => {
  return (
    <>
      {data.map((agentData, index) => {
        const {
          min,
          max,
          total,
          average,
          numCompleted,
          numNoShow,
          numUnattended,
          pctCompleted,
          pctNoShow,
          pctUnattended,
          agentName,
          graphData: data,
        } = agentData;

        let totalPct = (total / totalBranchTickets) * 100 || 0;
        const title = `${reportIndex || ""}.${branchIndex + 1}.${
          index + 1
        }. ${agentName}`;
        console.log(title)
        return (
          <>
            <Box className="reportContainer" mt="20px">
              <h3>
                {title}
              </h3>
              <Tag>
                <p className="branchTag">{branchName} Branch</p>
              </Tag>
              {total ? (
                <>
                  {" "}
                  <Table className="stripedTable" size="sm">
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th borderRight="1px solid #dadce0">Total Tickets</Th>
                        <Th borderRight="1px solid #dadce0">Average</Th>
                        <Th>Min</Th>
                        <Th>Max</Th>
                        <Th>Completed</Th>
                        <Th>No Show</Th>
                        <Th>Unattended</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>{index + 1}</Td>
                        <Td borderRight="1px solid #dadce0">
                          {" "}
                          <Text color={"var(--green)"}>
                            {formatNumber(total)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({totalPct.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>
                        <Td borderRight="1px solid #dadce0">
                          {formatNumber(average)}
                        </Td>
                        <Td>{formatNumber(min)}</Td>
                        <Td>{formatNumber(max)}</Td>
                        <Td>
                          {" "}
                          <Text color={"var(--green)"}>
                            {formatNumber(numCompleted)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctCompleted.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>

                        <Td>
                          {" "}
                          <Text color={"var(--red)"}>
                            {formatNumber(numNoShow)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctNoShow.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>

                        <Td>
                          {" "}
                          <Text color={"var(--red)"}>
                            {formatNumber(numUnattended)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctUnattended.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td colSpan={8}>
                          <Box mt="20px" mb="20px">
                            <AgentTicketStatusGraphs
                              data={data}
                              frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                              level={LEVELS.BRANCH}
                              title={`${agentName} Tickets, Day by Day Comparison`}
                            />
                          </Box>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </>
              ) : (
                <>
                  {" "}
                  <Text fontSize="sm">No data available for this report</Text>
                </>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
};

const AgentsTable = ({ data, branchId, reportIndex, branchIndex }) => {
  const { users, services, organizationId, branches, servicesSubscriptions } =
    useSelector((state) => state.defaultReducer);

  //split the services into agents.
  const usersClone = _.cloneDeep(users);
  const agents = usersClone.filter(
    (user) =>
      user.organizationId === organizationId && user.branchId === branchId
  );
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);

  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  ).services;

  const agentsMap = agents.reduce((acc, agent) => {
    acc[agent.id] = [];
    return acc;
  }, {});

  // Iterate over the data array and group objects by agentsId

  data.forEach((item) => {
    const agentId = item.manage?.userId;

    if (Array.isArray(agentsMap[agentId])) {
      agentsMap[agentId].push(item);
    }
  });

  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);

  const rowsData = Object.keys(agentsMap)
    .map((agentId) => {
      const data = agentsMap[agentId];
      const arrivalTimes = data.map((entry) => dayjs.unix(entry.created));

      const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
        const formattedDate = arrivalTime.format("YYYY-MM-DD");

        acc[formattedDate] = (acc[formattedDate] || 0) + 1;

        return acc;
      }, {});

      const rates = Object.entries(arrivalTimesPerDay)
        .map(([date, count]) => ({
          date,
          count,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      const min = rates.length ? Math.min(...rates.map((obj) => obj.count)) : 0;
      const max = rates.length ? Math.max(...rates.map((obj) => obj.count)) : 0;
      const total = rates.reduce((sum, entry) => sum + entry.count, 0);
      const average = rates.length ? total / rates.length : 0;

      const user = users.filter((user) => user.id === agentId)[0];

      //Completed
      const numCompleted = data.filter((b) => Number(b.status) === 3).length;

      const numNoShow = data.filter((b) => Number(b.status) === 2).length;

      const numUnattended = data.filter((b) => Number(b.status) < 2).length;
      const totalAgentTickets = numCompleted + numUnattended + numNoShow;

      const pctCompleted = (numCompleted / totalAgentTickets) * 100 || 0;
      const pctNoShow = (numNoShow / totalAgentTickets) * 100 || 0;
      const pctUnattended = (numUnattended / totalAgentTickets) * 100 || 0;

      const agentName = user
        ? `${user?.name} ${user?.lastname}`
        : "Unattended Tickets";

      return {
        min,
        max,
        total,
        average,
        numCompleted,
        numNoShow,
        numUnattended,
        totalAgentTickets,
        pctCompleted,
        pctNoShow,
        pctUnattended,
        agentName,
        graphData: data,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets in descending order
      if (b.total - a.total !== 0) {
          return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.agentName.localeCompare(b.agentName);
  });

    

  //More accurate than data.length, as some tickets may not have owners
  const totalBranchTickets = rowsData
    .map((a) => a.total)
    .reduce((acc, val) => {
      acc += val;
      return acc;
    }, 0);

  return (
    <Box>
      <h2>{title}</h2>
      <Text>{rowsData.length} agents.</Text>
      {!mapHasData(agentsMap) && (
        <Text fontSize="sm">No data available for this report</Text>
      )}

      {mapHasData(agentsMap) && (
        <>
          <AgentTables
            totalBranchTickets={totalBranchTickets}
            reportIndex={reportIndex}
            branchIndex={branchIndex}
            data={rowsData}
            branchName={branchName}
          />
        </>
      )}
    </Box>
  );
};

export default AgentsTable;
