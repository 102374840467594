import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  getOrganizations,
  getTickets,
  getServices,
  getServicesSubscriptions,
  getUsers,
  getBranches,
} from "./store/actions/defaultAction";

import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";

// start monthly report ----
import FullReport from "./pages/FullReport";
import FullreportArrivalRates from "./pages/FullreportArrivalRates";
import FullreportAgentPerformance from "./pages/FullreportAgentPerformance";
import FullreportTicketManagement from "./pages/FullreportTicketManagement";
// Organization
import Tickets from "./pages/organization/Tickets";
import TicketsPerService from "./pages/organization/TicketsPerService";
import ServiceTimePerService from "./pages/organization/ServiceTimePerService";
import ArrivalRatesPerHour from "./pages/organization/ArrivalRatesPerHour";
import ArrivalRatesPerDay from "./pages/organization/ArrivalRatesPerDay";
import ArrivalRatesPerService from "./pages/organization/ArrivalRatesPerService";
import TicketsPerBranch from "./pages/organization/TicketsPerBranch";

// Arrival rates
import BranchesArrivalRatesPerHour from "./pages/branches/ArrivalRatesPerHour";
import BranchesArrivalRatesPerDay from "./pages/branches/ArrivalRatesPerDay";
import BranchesArrivalRatesPerService from "./pages/branches/ArrivalRatesPerService";

// Service times
import ServiceTimePerServiceBranches from "./pages/branches/ServiceTimePerService";
import AgentServiceTimePerService from "./pages/branches/AgentServiceTimePerService";

// Ticket Management
import TicketsPerServiceBranches from "./pages/branches/TicketsPerService";
import TicketsPerAgents from "./pages/branches/TicketsPerAgents";
import AgentTicketsByService from "./pages/branches/AgentTicketsByService";

// end monthly reports

//Daily Reports
//organizations
import DailyOrganizationTickets from "./pages/organization/daily/OrganizationTickets";
import DailyOrganizationArrivalRatesPerHour from "./pages/organization/daily/OrganizationArrivalRatesPerHour";
import DailyOrganizationTicketsPerBranch from "./pages/organization/daily/OrganizationTicketsPerBranch";
import DailyOrganizationArrivalRatesPerService from "./pages/organization/daily/OrganizationArrivalRatesPerService";
import DailyOrganizationTicketsPerService from "./pages/organization/daily/OrganizationTicketsPerService";
import DailyOrganizationServiceTimePerService from "./pages/organization/daily/OrganizationServiceTimePerService";

//branches
import DailyBranchAgentServiceTimePerService from "./pages/branches/daily/BranchAgentServiceTimePerService";
import DailyBranchAgentTicketsPerService from "./pages/branches/daily/BranchAgentTicketsPerService";
import DailyBranchArrivalRatesPerHour from "./pages/branches/daily/BranchArrivalRatesPerHour";
import DailyBranchArrivalRatesPerService from "./pages/branches/daily/BranchArrivalRatesPerService";
import DailyBranchServiceTimePerService from "./pages/branches/daily/BranchServiceTimePerService";
import DailyBranchTicketsPerAgent from "./pages/branches/daily/BranchTicketsPerAgent";
import DailyBranchTicketsPerService from "./pages/branches/daily/BranchTicketsPerService";

//Login
import Login from "./pages/login/Login";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";

function App(props) {
  const { appStatus } = useSelector((state) => state.authReducer);
  const { getOrganizations } = props;
  useEffect(() => {
    if (appStatus) {
      getOrganizations();
    }
  }, [appStatus, getOrganizations]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  return (
    <ChakraProvider theme={theme}>
      {!appStatus ? (
        <>
          <Router>
            <ToastContainer position="bottom-center" />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Router>
        </>
      ) : (
        <Router>
          <Layout>
            <ToastContainer position="bottom-center" />
            <Routes>
              {/* Monthly*/}
              <Route
                path="/monthly/reports/full-report/"
                element={<FullReport />}
              />
              <Route
                path="/monthly/reports/arrival-times/"
                element={<FullreportArrivalRates />}
              />
              <Route
                path="/monthly/reports/agent-performance/"
                element={<FullreportAgentPerformance />}
              />
              <Route
                path="/monthly/reports/ticket-management/"
                element={<FullreportTicketManagement />}
              />
              <Route path="/" element={<Dashboard />} />

              {/* organization */}
              <Route
                path="/monthly/organization/tickets/"
                element={<Tickets />}
              />
              <Route
                path="/monthly/organization/tickets-per-service/"
                element={<TicketsPerService />}
              />
              <Route
                path="/monthly/organization/service-tim-per-service/"
                element={<ServiceTimePerService />}
              />
              <Route
                path="/monthly/organization/arrival-rates-per-hour"
                element={<ArrivalRatesPerHour />}
              />
              <Route
                path="/monthly/organization/arrival-rates-per-day"
                element={<ArrivalRatesPerDay />}
              />
              <Route
                path="/monthly/organization/arrival-rates-per-service"
                element={<ArrivalRatesPerService />}
              />
              <Route
                path="/monthly/organization/tickets-per-branch"
                element={<TicketsPerBranch />}
              />

              {/* Arrival Rates */}
              <Route
                path="/monthly/branches/arrival-rates-per-hour"
                element={<BranchesArrivalRatesPerHour />}
              />
              <Route
                path="/monthly/branches/arrival-rates-per-service/"
                element={<BranchesArrivalRatesPerService />}
              />
              <Route
                path="/monthly/branches/arrival-rates-per-day/"
                element={<BranchesArrivalRatesPerDay />}
              />

              {/* Service Times */}
              <Route
                path="/monthly/branches/service-tim-per-service/"
                element={<ServiceTimePerServiceBranches />}
              />
              <Route
                path="/monthly/branches/agent-service-time-per-service"
                element={<AgentServiceTimePerService />}
              />

              {/* Tickets */}
              <Route
                path="/monthly/branches/tickets-per-service/"
                element={<TicketsPerServiceBranches />}
              />
              <Route
                path="/monthly/branches/tickets-per-agents"
                element={<TicketsPerAgents />}
              />
              <Route
                path="/monthly/branches/agent-tickets-per-service"
                element={<AgentTicketsByService />}
              />

              {/*Weekly*/}

              <Route
                path="/weekly/reports/full-report/"
                element={<FullReport />}
              />
              <Route path="/" element={<Dashboard />} />

              {/* organization */}
              <Route
                path="/weekly/organization/tickets/"
                element={<Tickets />}
              />
              <Route
                path="/weekly/organization/tickets-per-service/"
                element={<TicketsPerService />}
              />
              <Route
                path="/weekly/organization/service-tim-per-service/"
                element={<ServiceTimePerService />}
              />
              <Route
                path="/weekly/organization/arrival-rates-per-hour"
                element={<ArrivalRatesPerHour />}
              />
              <Route
                path="/weekly/organization/arrival-rates-per-day"
                element={<ArrivalRatesPerDay />}
              />
              <Route
                path="/weekly/organization/arrival-rates-per-service"
                element={<ArrivalRatesPerService />}
              />
              <Route
                path="/weekly/organization/tickets-per-branch"
                element={<TicketsPerBranch />}
              />

              {/* Arrival Rates */}
              <Route
                path="/weekly/branches/arrival-rates-per-hour"
                element={<BranchesArrivalRatesPerHour />}
              />
              <Route
                path="/weekly/branches/arrival-rates-per-service/"
                element={<BranchesArrivalRatesPerService />}
              />
              <Route
                path="/weekly/branches/arrival-rates-per-day/"
                element={<BranchesArrivalRatesPerDay />}
              />

              {/* Service Times */}
              <Route
                path="/weekly/branches/service-tim-per-service/"
                element={<ServiceTimePerServiceBranches />}
              />
              <Route
                path="/weekly/branches/agent-service-time-per-service"
                element={<AgentServiceTimePerService />}
              />

              {/* Tickets */}
              <Route
                path="/weekly/branches/tickets-per-service/"
                element={<TicketsPerServiceBranches />}
              />
              <Route
                path="/weekly/branches/tickets-per-agents"
                element={<TicketsPerAgents />}
              />
              <Route
                path="/weekly/branches/agent-tickets-per-service"
                element={<AgentTicketsByService />}
              />

              {/*daily*/}

              <Route
                path="/daily/reports/full-report/"
                element={<FullReport />}
              />

              {/* organization */}
              <Route
                path="/daily/organization/tickets/"
                element={<DailyOrganizationTickets />}
              />
              <Route
                path="/daily/organization/tickets-per-service/"
                element={<DailyOrganizationTicketsPerService />}
              />
              <Route
                path="/daily/organization/service-tim-per-service/"
                element={<DailyOrganizationServiceTimePerService />}
              />
              <Route
                path="/daily/organization/arrival-rates-per-hour"
                element={<DailyOrganizationArrivalRatesPerHour />}
              />
              <Route
                path="/daily/organization/arrival-rates-per-service"
                element={<DailyOrganizationArrivalRatesPerService />}
              />
              <Route
                path="/daily/organization/tickets-per-branch"
                element={<DailyOrganizationTicketsPerBranch />}
              />

              {/* Arrival Rates */}
              <Route
                path="/daily/branches/arrival-rates-per-hour"
                element={<DailyBranchArrivalRatesPerHour />}
              />
              <Route
                path="/daily/branches/arrival-rates-per-service/"
                element={<DailyBranchArrivalRatesPerService />}
              />

              {/* Service Times */}
              <Route
                path="/daily/branches/service-tim-per-service/"
                element={<DailyBranchServiceTimePerService />}
              />
              <Route
                path="/daily/branches/agent-service-time-per-service"
                element={<DailyBranchAgentServiceTimePerService />}
              />

              {/* Tickets */}
              <Route
                path="/daily/branches/tickets-per-service/"
                element={<DailyBranchTicketsPerService />}
              />
              <Route
                path="/daily/branches/tickets-per-agents"
                element={<DailyBranchTicketsPerAgent />}
              />
              <Route
                path="/daily/branches/agent-tickets-per-service"
                element={<DailyBranchAgentTicketsPerService />}
              />

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Layout>
        </Router>
      )}
    </ChakraProvider>
  );
}

const mapStateToProps = (state) => {
  const { defaultReducer } = state;

  return {
    organizationId: defaultReducer.organizationId,
    branchId: defaultReducer.branchId,
    servicesSubscriptions: defaultReducer.servicesSubscriptions,
  };
};

export default connect(mapStateToProps, {
  getOrganizations,
  getTickets,
  getServices,
  getServicesSubscriptions,
  getUsers,
  getBranches,
})(App);
