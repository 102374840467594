import React from 'react';
import {
    Box, Heading, Text,
} from '@chakra-ui/react';
import { useSelector } from "react-redux";

import { reportDescriptions } from '../../store/constants';

import FilterForm from '../../components/FilterForm';
import ServiceTimePerService from "../../components/organization/reports/ServiceTimePerService";
import _ from 'lodash';
import { getReportDescription } from '../../store/helpers';

const ServiceTimePerServicePage = ({ showFilterForm = true, reportIndex = 1 }) => {
    const { tickets, reportRange } = useSelector((state)=> state.defaultReducer);
    const ticketsClone = _.cloneDeep(tickets);
    const reportTitle = `${reportIndex}. Service Time Per Service`;
    console.log(reportTitle);
    return (
        <Box>
            { showFilterForm ? <FilterForm /> : null }
            {
                ticketsClone.length
                ?   <Box mb="20px">
                        <h1>{reportTitle}</h1>
                        <p>{getReportDescription(reportRange).ServiceTimePerService}</p>
                    </Box>
                :   null
            }
            {
                ticketsClone.length
                ?   <ServiceTimePerService reportIndex={reportIndex} />
                :   null
            }
        </Box>
    );
};

export default ServiceTimePerServicePage;
  