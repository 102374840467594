import React from 'react';
import {
    Box
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import _ from 'lodash';

import FilterForm from '../../components/FilterForm';
import TicketsPerService from "../../components/organization/reports/TicketsPerService";
import { getReportDescription } from '../../store/helpers';

const TicketsPerServicePage = ({ showFilterForm = true, reportIndex = 1 }) => {
    const { tickets, reportRange } = useSelector((state)=> state.defaultReducer);
    const ticketsClone = _.cloneDeep(tickets);
    const reportTitle = `${reportIndex}. Tickets Per Service`;
    console.log(reportTitle);
    return (
        <Box>
            {
            // create a page break if this is not the first report
            Number(reportIndex) !== 1
                ?   <Box className='forcePageBreak'></Box>
                :   null
            }
            { showFilterForm ? <FilterForm /> : null }
            {
                ticketsClone.length
                ?   <Box mb="20px">
                        <h1>{reportTitle}</h1>
                        <p>{getReportDescription(reportRange).TicketsPerService}</p>
                    </Box>
                :   null
            }
            {
                ticketsClone.length
                ?   <TicketsPerService reportIndex={reportIndex} />
                :   null
            }
        </Box>
    );
};

export default TicketsPerServicePage;
  