import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  HStack,
  Tag
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import TicketsPerServiceLineGraph from "../charts/TicketsPerServiceLineGraph";
import TicketsPerServicePieGraph from "../charts/TicketsPerServicePieGraph";

const { formatNumber } = require("../../../store/helpers");

const TicketsPerServiceTable = ({
  branchId = null,
  subscribedServices = null,
  data,
  reportIndex,
  branchIndex,
}) => {
  const { branches, showEmptyDataSets } = useSelector((state) => state.defaultReducer);
  const branchesClone = _.cloneDeep(branches);

  let branch = {};
  let chartTitle = "Entire Organization,";
  if (branchId) {
    branch = branchesClone.find((a) => a.id === branchId);
    chartTitle = branch ? `${branch?.name} Branch, ` : "Branch..."; //tends to be undefined
  }

  const branchTitle = branchId ? `${reportIndex}. ${ branchIndex ? branchIndex + ". " : "" }${ branch.name } Branch` : "Entire Organization";
  console.log(branchTitle);
  return (
    <Box  mt={10}>
      {
        branchId
        ? <Box mb="20px">
            <h2>{branchTitle}</h2>
            <p>{ subscribedServices.length } services.</p>
          </Box>
        : <Box mb="20px">
            <h2>{reportIndex}. 1. Entire Organization</h2>
          </Box>
      }
      {
      data.map((a, index) => {
        console.log(`${reportIndex}. ${ branchIndex ? branchIndex + ". " : "" }${index+1}. ${a.name}`)

        return <Box key={index} className="reportContainer" mt="20px">
          <Box mb="10px">
            <h3>{reportIndex}. {branchIndex ? branchIndex + ". " : null}{index+1}. {a.name}</h3>
            { branchId ? <Tag><p className="branchTag">{ branch.name } Branch</p></Tag> : null }
          </Box>
          {
            showEmptyDataSets|| ( !showEmptyDataSets && Number(a.total) > 0 ) 
            ? <Table className="stripedTable" size="sm">
                <Thead>
                  <Tr>
                    <Th borderRight="1px solid #dadce0">Total Tickets</Th>
                    <Th>Avg /day.</Th>
                    <Th>Min.</Th>
                    <Th borderRight="1px solid #dadce0">Max</Th>
                    <Th>Completed</Th>
                    <Th>No Show</Th>
                    <Th>Unattended</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td borderRight="1px solid #dadce0">
                      <Text color={"var(--blue)"}>{formatNumber(a.total)}</Text>
                    </Td>
                    <Td>
                      <Text>{formatNumber(a.averageTicketsPerDay)}</Text>
                    </Td>
                    <Td>
                      <Text>{formatNumber(a.minTicketsPerDay)}</Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      <Text>{formatNumber(a.maxTicketsPerDay)}</Text>
                    </Td>
                    <Td>
                      <Text color={"var(--green)"}>
                        {formatNumber(a.completed)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({a.pctCompleted.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>
                    <Td>
                      <Text cursor="pointer" color={"var(--red)"}>
                        {formatNumber(a.noShow)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({a.pctNoShow.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>
                    <Td>
                      <Text color={"var(--red)"}>
                        {formatNumber(a.unattended)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({a.pctUnattended.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={9} p={0}>
                      <HStack>
                        <Box w="30%">
                          <TicketsPerServicePieGraph
                            title={`Ticket Management Comparison`}
                            chart={a}
                            chartHeight="200px"
                          />
                        </Box>
                        <Box w="70%" key={3423}>
                          <TicketsPerServiceLineGraph
                            title={`${a.name}: ${chartTitle} Day by Day Comparison`}
                            chart={a.days}
                            chartHeight="200px"
                          />
                        </Box>
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            : <Text fontSize="sm">No data available for this report</Text>
          }
        </Box>
}
)}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { defaultReducer } = state;
  return {
    tickets: defaultReducer.tickets,
  };
};

export default connect(mapStateToProps, {})(TicketsPerServiceTable);
