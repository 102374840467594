import React from 'react';
import {
    Box, Heading, Text,
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import _ from 'lodash';


import FilterForm from '../../components/FilterForm';
import ServiceTimePerService from "../../components/organization/reports/ServiceTimePerService";
import { getReportDescription } from '../../store/helpers';

const ServiceTimePerServicePage = ({ showFilterForm = true, reportIndex = 1 }) => {
    const { branches, tickets, organizationId, reportRange } = useSelector((state)=> state.defaultReducer);
    const branchesClone = _.cloneDeep(branches);
    const ticketsClone = _.cloneDeep(tickets);

    const reportTitle = `${reportIndex}. Service Time Per Service`;
    console.log(reportTitle);

    return (
        <Box>
            { showFilterForm ? <FilterForm /> : null }
            {
                ticketsClone.length
                ?   <Box mb="20px">
                        <h1>{reportTitle}</h1>
                        <p>{getReportDescription(reportRange).ServiceTimePerServiceBranches}</p>
                    </Box>
                :   null
            }
            { ticketsClone.length
                ?   branchesClone
                        .filter( (a) => 
                        a.organizationId === organizationId
                        && a.reportStatus != null
                        && Number(a.reportStatus) 
                    )
                    .map( (a, index) => {
                        return <ServiceTimePerService key={index} branchId={a.id} reportIndex={reportIndex} branchIndex={index+1} />
                    })
                : null 
            }
        </Box>
    );
};

export default ServiceTimePerServicePage;
  