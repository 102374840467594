import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

// Filters
import FilterForm from '../components/FilterForm';

// Reports
// organization
import AgentServiceTimePerService from './branches/AgentServiceTimePerService';
import AgentTicketsByService from './branches/AgentTicketsByService';
import TicketsPerAgents from './branches/TicketsPerAgents';

const FullReport = () => {
    
    const report = [
        <TicketsPerAgents/>,
        <AgentTicketsByService/>,
        <AgentServiceTimePerService/>,
    ]
    return (
        <Box>
            <FilterForm />
            {
                report.map((a, index) => React.cloneElement(a, { key: index, reportIndex: index+1, showFilterForm: 0 }))
            }
        </Box>
    );
};

export default FullReport;
  