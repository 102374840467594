export const version = "1.8.22";
export const ARRIVAL_RATES_FREQUENCIES = Object.freeze({ HOUR: "0", DAY: "1" });
export const LEVELS = Object.freeze({ ORGANIZATION: "0", BRANCH: "1" });
export const completed = 3;
export const noShow = 2;
export const transferred = 1;
export const TICKET_STATUS = Object.freeze({
  COMPLETED: 3,
  NO_SHOW: 2,
  TRANSFERRED: 1,
});

export const REPORT_RANGES = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
});

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

export const navLinks = Object.freeze([
  {
    name: "month",
    links: [
      {
        name: "Reports",
        links: [
          {
            name: "Monthly - Full Report",
            link: "/monthly/reports/full-report/",
          },
          {
            name: "Monthly - Arrival Rates & Waiting Times",
            link: "/monthly/reports/arrival-times/",
          },
          {
            name: "Monthly - Agent Performance",
            link: "/monthly/reports/agent-performance/",
          },
          {
            name: "Monthly - Ticket Management",
            link: "/monthly/reports/ticket-management/",
          },
        ],
      },
      {
        name: "Entire Organization",
        links: [
          {
            name: "Tickets",
            link: "/monthly/organization/tickets/",
          },
          {
            name: "Tickets Per Service",
            link: "/monthly/organization/tickets-per-service/",
          },
          {
            name: "Service Time Per Service",
            link: "/monthly/organization/service-tim-per-service/",
          },
          {
            name: "Arrival Rates Per Hour",
            link: "/monthly/organization/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/monthly/organization/arrival-rates-per-day/",
          },

          {
            name: "Arrival Rates Per Service",
            link: "/monthly/organization/arrival-rates-per-service/",
          },
          {
            name: "Tickets Per Branch",
            link: "/monthly/organization/tickets-per-branch/",
          },
        ],
      },
      {
        name: "Arrival Rates",
        links: [
          {
            name: "Arrival Rates Per Hour",
            link: "/monthly/branches/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/monthly/branches/arrival-rates-per-day/",
          },
          {
            name: "Arrival Rates Per Service",
            link: "/monthly/branches/arrival-rates-per-service/",
          },
        ],
      },
      {
        name: "Service Times",
        links: [
          {
            name: "Service Time Per Service",
            link: "/monthly/branches/service-tim-per-service/",
          },
          {
            name: "Agent Service Time Per Service",
            link: "/monthly/branches/agent-service-time-per-service/",
          },
        ],
      },
      {
        name: "Tickets",
        links: [
          {
            name: "Tickets Per Service",
            link: "/monthly/branches/tickets-per-service/",
          },
          {
            name: "Tickets Per Agent",
            link: "/monthly/branches/tickets-per-agents/",
          },
          {
            name: "Agent Tickets Per Service",
            link: "/monthly/branches/agent-tickets-per-service/",
          },
        ],
      },
    ],
  },
  {
    name: "week",
    links: [
      {
        name: "Entire Organization",
        links: [
          {
            name: "Tickets",
            link: "/weekly/organization/tickets/",
          },
          {
            name: "Tickets Per Service",
            link: "/weekly/organization/tickets-per-service/",
          },
          {
            name: "Service Time Per Service",
            link: "/weekly/organization/service-tim-per-service/",
          },
          {
            name: "Arrival Rates Per Hour",
            link: "/weekly/organization/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/weekly/organization/arrival-rates-per-day/",
          },

          {
            name: "Arrival Rates Per Service",
            link: "/weekly/organization/arrival-rates-per-service/",
          },
          {
            name: "Tickets Per Branch",
            link: "/weekly/organization/tickets-per-branch/",
          },
        ],
      },
      {
        name: "Arrival Rates",
        links: [
          {
            name: "Arrival Rates Per Hour",
            link: "/weekly/branches/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/weekly/branches/arrival-rates-per-day/",
          },
          {
            name: "Arrival Rates Per Service",
            link: "/weekly/branches/arrival-rates-per-service/",
          },
        ],
      },
      {
        name: "Service Times",
        links: [
          {
            name: "Service Time Per Service",
            link: "/weekly/branches/service-tim-per-service/",
          },
          {
            name: "Agent Service Time Per Service",
            link: "/weekly/branches/agent-service-time-per-service/",
          },
        ],
      },
      {
        name: "Tickets",
        links: [
          {
            name: "Tickets Per Service",
            link: "/weekly/branches/tickets-per-service/",
          },
          {
            name: "Tickets Per Agent",
            link: "/weekly/branches/tickets-per-agents/",
          },
          {
            name: "Agent Tickets Per Service",
            link: "/weekly/branches/agent-tickets-per-service/",
          },
        ],
      },
    ],
  },
  {
    name: "day",
    links: [
      {
        name: "Entire Organization",
        links: [
          {
            name: "Tickets",
            link: "/daily/organization/tickets/",
          },
          {
            name: "Tickets Per Service",
            link: "/daily/organization/tickets-per-service/",
          },
          {
            name: "Service Time Per Service",
            link: "/daily/organization/service-tim-per-service/",
          },
          {
            name: "Arrival Rates Per Hour",
            link: "/daily/organization/arrival-rates-per-hour/",
          },

          {
            name: "Arrival Rates Per Service",
            link: "/daily/organization/arrival-rates-per-service/",
          },
          {
            name: "Tickets Per Branch",
            link: "/daily/organization/tickets-per-branch/",
          },
        ],
      },
      {
        name: "Arrival Rates",
        links: [
          {
            name: "Arrival Rates Per Hour",
            link: "/daily/branches/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Service",
            link: "/daily/branches/arrival-rates-per-service/",
          },
        ],
      },
      {
        name: "Service Times",
        links: [
          {
            name: "Service Time Per Service",
            link: "/daily/branches/service-tim-per-service/",
          },
          {
            name: "Agent Service Time Per Service",
            link: "/daily/branches/agent-service-time-per-service/",
          },
        ],
      },
      {
        name: "Tickets",
        links: [
          {
            name: "Tickets Per Service",
            link: "/daily/branches/tickets-per-service/",
          },
          {
            name: "Tickets Per Agent",
            link: "/daily/branches/tickets-per-agents/",
          },
          {
            name: "Agent Tickets Per Service",
            link: "/daily/branches/agent-tickets-per-service/",
          },
        ],
      },
    ],
  },
]);
