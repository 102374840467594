import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";
import OrganizationArrivalRatesPerServiceReport from "../../../components/organization/reports/daily/OrganizationArrivalRatesPerService";

const OrganizationArrivalRatesPerService = ({
  showFilterForm = true,
  reportIndex = 1,
}) => {
  const { reportRange, loading, tickets } = useSelector(
    (state) => state.defaultReducer
  );
  const title = `${reportIndex}. Arrival Rates Per Service`;
  console.log(title);
  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{title}</h1>
              <p>{getReportDescription(reportRange).ArrivalRatesByService}</p>

              <OrganizationArrivalRatesPerServiceReport
                reportIndex={reportIndex}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrganizationArrivalRatesPerService;
