import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import { getReportDescription } from "../../../store/helpers";
import Loader from "../../../components/loader";
import BranchAgentServiceTimePerServiceReport from "../../../components/branches/reports/daily/BranchAgentServiceTimePerService";
import _ from "lodash";

const BranchAgentServiceTimePerService = ({
  showFilterForm = true,
  reportIndex = 1,
}) => {
  const { reportRange, loading, tickets, branches, organizationId, branchId } =
    useSelector((state) => state.defaultReducer);
  let branchesClone = _.cloneDeep(branches);
  branchesClone = branchesClone
    .filter(
      (a) =>
        a.reportStatus != null &&
        Number(a.reportStatus) &&
        a.organizationId === organizationId
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));

  const title = `${reportIndex}. Agent Service Time Per Service`;
  console.log(title);
  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{title}</h1>
              <p>{getReportDescription(reportRange).AgentServiceSessionTime}</p>

              {branchesClone?.map((a, i) => {
                return (
                  <BranchAgentServiceTimePerServiceReport
                    key={a.id}
                    reportIndex={reportIndex}
                    branchId={a.id}
                    branchIndex={i + 1}
                  />
                );
              })}
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BranchAgentServiceTimePerService;
