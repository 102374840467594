import _ from "lodash";

import { Box, Divider } from "@chakra-ui/react";

import FilterForm from "../../components/FilterForm";
import ArrivalRatesTable from "../../components/organization/ArrivalRatesTable";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../store/constants";
import ArrivalRatesLineGraph from "../../components/ArrivalRatesLineGraph";
import Loader from "../../components/loader";
import { reportDescriptions } from "../../store/constants";
import { getReportDescription } from "../../store/helpers";

const ArrivalRatesPerDay = ({ showFilterForm = true, reportIndex = 1 }) => {
  const { tickets, loading, reportRange } = useSelector(
    (state) => state.defaultReducer
  );
  const ticketsClone = _.cloneDeep(tickets);

  //Entire services
  const arrivalTimes = ticketsClone.map((entry) => dayjs.unix(entry.created));

  const arrivalTimesPerDay = arrivalTimes.reduce((acc, arrivalTime) => {
    const formattedDate = arrivalTime.format("YYYY-MM-DD");

    acc[formattedDate] = (acc[formattedDate] || 0) + 1;

    return acc;
  }, {});

  //By service

  const title = `${reportIndex}. Arrival Rates Per Day`;
  console.log(title);
  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <Box mb="20px">
                <h1>{title}</h1>
                <p>{getReportDescription(reportRange).ArrivalRatesPerDay}</p>
              </Box>
              <Box className="reportContainer" mt="20px">
                <ArrivalRatesTable
                  arrivalTimesPerDay={arrivalTimesPerDay}
                  frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                  reportIndex={reportIndex}
                />

                <Box mt="20px" mb="20px">
                  <ArrivalRatesLineGraph
                    arrivalTimesPerDay={arrivalTimesPerDay}
                    frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                    level={LEVELS.ORGANIZATION}
                    height={"300px"}
                    title={
                      "Entire Organization Arrival Rates, Day by Day Comparison"
                    }
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerDay;
