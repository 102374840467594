import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { generateHoursArray } from "../../../../store/helpers";
import { Box } from "@chakra-ui/react";
import { TICKET_STATUS } from "../../../../store/constants";
import TicketsPerAgentTable from "../../tables/daily/TicketsPerAgentTable";

const BranchTicketsPerAgent = ({ branchId, reportIndex, branchIndex }) => {
  const { tickets, dateRange, workDay, branches, organizationId, users } =
    useSelector((state) => state.defaultReducer);

  const ticketsClone = _.cloneDeep(tickets);
  const usersClone = _.cloneDeep(users);

  const branchData = ticketsClone.filter((a) => a.branchId === branchId);
  const agents = usersClone.filter(
    (user) =>
      user.organizationId === organizationId && user.branchId === branchId
  );

  const agentsMap = agents.reduce((acc, agent) => {
    acc[agent.id] = [];
    return acc;
  }, {});

  // Iterate over the data array and group objects by agentsId
  branchData.forEach((item) => {
    const agentId = item.manage?.userId;

    if (Array.isArray(agentsMap[agentId])) {
      agentsMap[agentId].push(item);
    }
  });

  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  const agentsData = Object.keys(agentsMap)
    .map((agentId) => {
      const agentData = agentsMap[agentId];

      const user = users.filter((user) => user.id === agentId)[0];
      const agentName = user
        ? `${user?.name} ${user?.lastname}`
        : "Unattended Tickets";
      const hoursBetween = generateHoursArray(dateRange[0], dateRange[1]);
      const chart = hoursBetween
        .map((hour) => {
          const hourTickets = agentData.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );
          console.log(hourTickets.length);
          const completed = hourTickets.filter(
            (a) => Number(a.status) === TICKET_STATUS.COMPLETED
          ).length;
          const no_show = hourTickets.filter(
            (a) => Number(a.status) === TICKET_STATUS.NO_SHOW
          ).length;
          const unattended = hourTickets.filter(
            (a) => Number(a.status) < 2
          ).length;

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
              completed,
              unattended,
              no_show,
            };
          return null;
        })
        .filter(Boolean);
      const min = Math.min(...chart.map((a) => a.total)) || 0;
      const max = Math.max(...chart.map((a) => a.total)) || 0;
      const average =
        chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const numCompleted = chart.reduce((a, b) => a + b.completed, 0) || 0;
      const numNoShow = chart.reduce((a, b) => a + b.no_show, 0) || 0;
      const numUnattended = chart.reduce((a, b) => a + b.unattended, 0) || 0;
      const totalAgentTickets = numCompleted + numUnattended + numNoShow;
      const pctCompleted = (numCompleted / totalAgentTickets) * 100 || 0;
      const pctNoShow = (numNoShow / totalAgentTickets) * 100 || 0;
      const pctUnattended = (numUnattended / totalAgentTickets) * 100 || 0;

      return {
        min,
        max,
        total,
        average,
        numCompleted,
        numNoShow,
        numUnattended,
        totalAgentTickets,
        pctCompleted,
        pctNoShow,
        pctUnattended,
        agentName,
        chart,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.agentName.localeCompare(b.agentName);
    });
  const data = { branchId, agentsData, branchName };
  console.log(data);
  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);

  return (
    <Box key={branchId}>
      <h2>{title}</h2>
      <TicketsPerAgentTable
        reportIndex={reportIndex}
        data={data}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default BranchTicketsPerAgent;
