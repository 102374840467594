import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import FilterForm from "../../components/FilterForm";
import AgentServicesSessionTime from "../../components/branches/reports/AgentServicesSessionTime";
import {
  TICKET_STATUS,
} from "../../store/constants";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";

const AgentServiceTimePerService = ({
  showFilterForm = true,
  reportIndex = 1,
}) => {
  const { tickets, branches, organizationId, loading, reportRange, branchId } =
    useSelector((state) => state.defaultReducer);
  const ticketsClone = _.cloneDeep(tickets);

  const completed = ticketsClone.filter(
    (ticket) => Number(ticket.status) === Number(TICKET_STATUS.COMPLETED)
  );

  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (branch) =>
        branch.organizationId === organizationId && Number(branch.reportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));

  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  completed.forEach((data) => {
    const branchId = data.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(data);
    }
  });

  const title = `${reportIndex}. Agent Service Time Per Service`;
  console.log(title);

  return (
    <Box>
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {tickets.length ? (
            <>
              <h1>{title}</h1>
              <p>{getReportDescription(reportRange).AgentServiceSessionTime}</p>

              {Object.keys(branchesMap).map((branchId, index) => {
                return (
                  <AgentServicesSessionTime
                    branchData={branchesMap[branchId]}
                    branchId={branchId}
                    reportIndex={reportIndex}
                    branchIndex={index}
                  />
                );
              })}
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AgentServiceTimePerService;
