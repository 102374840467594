import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import ArrivalRatesPerHourTable from "../../tables/daily/ArrivalRatesPerHourTable";
import { Box, Text } from "@chakra-ui/react";

const BranchArrivalRatesPerHour = ({ branchId, reportIndex, branchIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
  } = useSelector((state) => state.defaultReducer);
  let ticketsClone = _.cloneDeep(tickets).filter(
    (a) => a.branchId === branchId
  );
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);
  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  const hoursBetween = generateHoursArray(dateRange[0], dateRange[1]);

  const chart = hoursBetween
    .map((hour) => {
      const hourTickets = ticketsClone.filter(
        (a) => dayjs.unix(a.created).format("h A") === hour
      );

      if (workDay.includes(hour))
        return {
          hour,
          total: hourTickets.length,
        };
      return null;
    })
    .filter(Boolean);

  const min = Math.min(...chart.map((a) => a.total)) || 0;
  const max = Math.max(...chart.map((a) => a.total)) || 0;
  const total = chart.reduce((a, b) => a + b.total, 0) || 0;
  const average = chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
  const data = {
    average,
    min,
    max,
    total,
    chart,
    branchName,
  };

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);
  return (
    <Box>
      <h2>{title}</h2>
      <Text>{branchServices.length} services</Text>

      <ArrivalRatesPerHourTable data={data} index={branchIndex} />
    </Box>
  );
};

export default BranchArrivalRatesPerHour;
