import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ServicesSessionTimeTable from "../tables/ServicesSessionTimeTable";
import _ from "lodash";
import { checkServiceHasChildren } from "../../../store/helpers";

const AgentServicesSessionTime = ({ branchData, branchId, reportIndex, branchIndex }) => {
  const { branches, hoursPerDay, servicesSubscriptions, services } =
    useSelector((state) => state.defaultReducer);

  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);

  const servicesMap = {};
  branchServices.forEach((serviceId) => {
    servicesMap[serviceId] = [];
  });

  // Iterate over the data array and group objects by serviceId
  branchData.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

    const title = `${reportIndex || ''}.${branchIndex+1}. ${branchName}`;
    console.log(title)

  return (
    <Box>
      <h2>{title}</h2>
      <Text>{branchServices.length} services.</Text>

      {Object.keys(servicesMap).map((serviceId, index) => {
        return (
          <ServicesSessionTimeTable
            key={index}
            serviceId={serviceId}
            serviceData={servicesMap[serviceId]}
            branchId={branchId}
            branchName={branchName}
            reportIndex={reportIndex}
            branchIndex={branchIndex}
            index={index}
          />
        );
      })}
    </Box>
  );
};

export default AgentServicesSessionTime;
