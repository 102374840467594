import { Box, Heading } from "@chakra-ui/react";
import { TICKET_STATUS } from "../../../store/constants";
import dayjs from "dayjs";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { generateDateArray } from "../../../store/helpers";

const ServiceSessionTimeGraphs = ({ data, title, height }) => {
  const { workWeek, dateRange } = useSelector((store) => store.defaultReducer);
  const completedTickets = data.filter(
    (ticket) => Number(ticket.status) === TICKET_STATUS.COMPLETED
  );


  const daysMap = {};

  // Iterate over the data array and group objects by date
  completedTickets.forEach((item) => {
    const formattedDate = dayjs.unix(item.created).format("MM-DD-YYYY");

    if (!daysMap[formattedDate]) {
      daysMap[formattedDate] = {
        totalSessionSeconds: 0,
        totalCompleted: 0,
        fullDate: new Date(formattedDate),
        date: Number(dayjs.unix(item.created).get("date")),
      };
    }

    daysMap[formattedDate].totalSessionSeconds += item.sessionInSeconds || 0;
    daysMap[formattedDate].totalCompleted += 1;
  });

  const daysArray = Object.values(daysMap).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );


  const datesBetween = generateDateArray(dateRange[0], dateRange[1]);

  const finalData = datesBetween
    .map((fullDate, i) => {
      let date = dayjs(fullDate).date();
      const correspondingData = daysArray.find(
        (entry) =>
          dayjs(entry.fullDate).format("MM-DD-YYYY") ===
          dayjs(fullDate).format("MM-DD-YYYY")
      );

      if (workWeek.includes(new Date(fullDate).getDay()))
        return {
          date,
          dailyAverage:
            Math.round(
              correspondingData?.totalSessionSeconds /
                correspondingData?.totalCompleted
            ) || 0,
        };

      return null;
    })
    .filter(Boolean);

  return (
    <>
      <Box width="100%" h={height || "300px"}>
        <ResponsiveContainer>
          <LineChart
            data={finalData}
            margin={{
              top: 0,
              right: 10,
              left: -10,
              bottom: 15,
            }}
          >
            <CartesianGrid stroke="#EBEBEB" vertical={false} />
            <XAxis
              dataKey="date"
              name="Day"
              axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
            />
            <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
            <Tooltip />
            <Legend width="100%" />
            <Line
              type="monotone"
              strokeWidth={1.6}
              dataKey="dailyAverage"
              name="Session Time"
              stroke={"var(--blue)"}
              dot={{ fill: "var(--blue)", r: 2 }}
              activeDot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
        {title && (
          <Heading
            size="sm"
            fontWeight={400}
            fontSize="14px"
            color="#535353"
            mb="20px"
            textAlign="center"
          >
            {title}
          </Heading>
        )}
      </Box>
    </>
  );
};

export default ServiceSessionTimeGraphs;
