import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import ServiceSessionTimeGraphs from "../charts/ServiceSessionTimeGraphs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { mapHasData } from "../../../store/helpers";
import { formatTime } from "../../../store/helpers";

const AgentServicesTableRows = ({ data }) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  return (
    <>
      {data.map((agentData, index) => {
        const { agentName, serviceName } = agentData;
        return agentData.sum ? (
          <>
            <Box mt="20px" mb="20px">
              <Table className="stripedTable" size="sm">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Agent Name</Th>
                    <Th borderRight="1px solid #dadce0">Total Service Time</Th>
                    <Th borderRight="1px solid #dadce0">Average</Th>
                    <Th>Min</Th>
                    <Th>Max</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>{agentData.agentName}</Td>
                    <Td borderRight="1px solid #dadce0">
                      {" "}
                      <Text color={"var(--green)"}>
                        {formatTime(agentData.sum)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({agentData.totalPct.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      {formatTime(agentData.average)}
                    </Td>
                    <Td>{formatTime(agentData.min)}</Td>
                    <Td>{formatTime(agentData.max)}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={6}>
                      <Box mt="20px" mb="20px">
                        <ServiceSessionTimeGraphs
                          title={`${agentName}: ${serviceName}, Day by Day Comparison`}
                          data={agentData.graphData}
                          height={"200px"}
                        />
                      </Box>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </>
        ) : (
          <>
            <Text fontWeight={500}>
              No data for agent: {agentData.agentName}
            </Text>
          </>
        );
      })}
    </>
  );
};

const ServicesSessionTimeTable = ({
  serviceData,
  serviceId,
  branchId,
  branchName,
  reportIndex,
  branchIndex,
  index,
}) => {
  const { services, users, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const serviceName =
    services.find((service) => serviceId === service.id)?.name || "Unnamed";

  let totalServiceTime = 0;

  const usersClone = _.cloneDeep(users);
  const agents = usersClone.filter(
    (user) =>
      user.organizationId === organizationId &&
      user.branchId === branchId &&
      user.services.includes(serviceId)
  );

  const agentsMap = {};
  agents.forEach((agent) => {
    agentsMap[agent.id] = [];
    return;
  });

  // Iterate over the data array and group objects by agentsId

  serviceData.forEach((item) => {
    const agentId = item.manage?.userId;

    if (Array.isArray(agentsMap[agentId])) {
      agentsMap[agentId].push(item);
    }
    totalServiceTime += item.sessionInSeconds;
  });


  const rowsData = Object.keys(agentsMap)
    .map((agentId) => {
      const data = agentsMap[agentId];
      const user = users.find((user) => user.id === agentId);

      const daysMap = {};

      // Iterate over the data array and group objects by date
      data.forEach((item) => {
        const formattedDate = dayjs.unix(item.created).format("MM-DD-YYYY");

        if (!daysMap[formattedDate]) {
          daysMap[formattedDate] = 0;
        }

        daysMap[formattedDate] += item?.sessionInSeconds || 0;
      });

      const daysArray = Object.values(daysMap);
      const min = daysArray.length ? Math.min(...daysArray) : 0;
      const max = daysArray.length ? Math.max(...daysArray) : 0;

      const sum = daysArray.reduce((acc, val) => acc + val, 0);

      // Calculate the average
      const average = daysArray.length ? sum / daysArray.length : 0;

      const agentName = user ? user?.name + " " + user?.lastname : "Unnamed";
      let totalPct = (sum / totalServiceTime) * 100 || 0;

      return {
        min,
        max,
        average,
        agentName,
        totalPct,
        sum,
        serviceName,
        graphData: data,
      };
    })
    .sort((a, b) => {
      // Sort by total session time in descending order
      if (b.sum - a.sum !== 0) {
          return b.sum - a.sum;
      }
      // If totalSessionTime is the same, sort alphabetically by name
      return a.agentName.localeCompare(b.agentName);
  });

    const title = `${reportIndex || ""}.${branchIndex + 1}.${index + 1}. ${serviceName}`;

  return (
    <Box>
      <h3>
        {title}
      </h3>
      {!mapHasData(agentsMap) && (
        <Text fontSize="sm">No data available for this report</Text>
      )}
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {mapHasData(agentsMap) && (
        <>
          <AgentServicesTableRows data={rowsData} />
        </>
      )}
    </Box>
  );
};

export default ServicesSessionTimeTable;
