import { Box } from "@chakra-ui/react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import { TICKET_STATUS } from "../../../../store/constants";
import OrganizationServiceTimePerServiceTable from "../../tables/daily/OrganizationServiceTimePerServiceTable";

const OrganizationServiceTimePerService = ({ reportIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);
  let branchesClone = _.cloneDeep(branches);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const orgBranches = branchesClone.filter(
    (a) => a.organizationId === organizationId && Number(a.reportStatus)
  );

  let orgServices = [];
  orgBranches.forEach((branch) => {
    const branchServices =
      servicesSubscriptionsClone.find(
        (service) => service.branchId === branch.id
      )?.services || [];
    orgServices.push(...branchServices);
  });

  let updatedServices = checkServiceHasChildren(servicesClone).filter(
    (service) => orgServices?.includes(service.id) && !service.hasChildren
  );

  const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);

  const serviceStatsObject = {
    sessionInSeconds: 0,
    completed: 0,
    avg: 0,
    min: 0,
    max: 0,

    hours: hoursArray
      .map((hour, i) => {
        if (workDay.includes(hour))
          return {
            hour,
            sessionInSeconds: 0,
            completed: 0,
            avg: 0,
          };

        return null;
      })
      .filter(Boolean),
  };

  const managementStats = updatedServices
    .filter((a) => !a.hasChildren)
    .map((a) => ({
      ..._.cloneDeep(serviceStatsObject),
      id: a.id,
      name: a.name,
    }));

  // loop through tickets and update stats
  ticketsClone
    .filter((a) => a.status === TICKET_STATUS.COMPLETED)
    .forEach((a) => {
      let serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
      if (serviceIndex === -1) {
        managementStats.push({
          ..._.cloneDeep(serviceStatsObject),
          id: a.serviceId,
          name: a.serviceName,
        });
        serviceIndex = managementStats.findIndex((b) => b.id === a.serviceId);
      }

      const hourIndex = managementStats[serviceIndex].hours.findIndex(
        (b) => b.hour === dayjs.unix(a.created).format("h A")
      );
      if (hourIndex > -1) {
        //what to do with tickets outside the workHours
        managementStats[serviceIndex].completed += 1;
        managementStats[serviceIndex].hours[hourIndex].completed += 1;
        managementStats[serviceIndex].sessionInSeconds += a.sessionInSeconds;
        managementStats[serviceIndex].hours[hourIndex].sessionInSeconds +=
          a.sessionInSeconds;

        if (a.status === 3) {
          managementStats[serviceIndex].completed += 1;
          managementStats[serviceIndex].hours[hourIndex].completed += 1;
        }
      }
    });

  // update pct completed, no show, and unattended
  managementStats.forEach((a, serviceIndex) => {
    // for each service loop hours and calculate avg
    a.hours.forEach((b, hourIndex) => {
      managementStats[serviceIndex].hours[hourIndex].avg =
        b.completed !== 0 ? (b.sessionInSeconds / b.completed).toFixed(2) : 0;
    });

    a.avg =
      a.completed !== 0 ? (a.sessionInSeconds / a.completed).toFixed(2) : 0;
    let min = Math.min(...a.hours.map((b) => b.avg));
    let max = Math.max(...a.hours.map((b) => b.avg));

    a.min = isFinite(min) ? min : 0;
    a.max = isFinite(max) ? max : 0;
  });

  // sort by sessionInSeconds in descending order, followed by all empty data sets sorted alphabetically
  managementStats.sort((a, b) => {
    // Sort by sessionInSeconds in descending order
    if (b.sessionInSeconds - a.sessionInSeconds !== 0) {
      return b.sessionInSeconds - a.sessionInSeconds;
    }
    // If sessionInSeconds is the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  console.log(managementStats);
  return (
    <Box>
      <OrganizationServiceTimePerServiceTable
        branchId={null}
        data={managementStats}
        numServices={null}
        branchName={null}
        reportIndex={reportIndex}
        branchIndex={null}
      />
    </Box>
  );
};

export default OrganizationServiceTimePerService;
