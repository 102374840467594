import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { TICKET_STATUS } from "../../../../store/constants";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import AgentServiceTimePerServiceTable from "../../tables/daily/AgentServiceTimePerServiceTable";
import { Box, Text } from "@chakra-ui/react";

const BranchAgentServiceTimePerService = ({
  branchId,
  reportIndex,
  branchIndex,
}) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
    users,
  } = useSelector((state) => state.defaultReducer);
  const usersClone = _.cloneDeep(users);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);
  let branchesClone = _.cloneDeep(branches);

  ///separate into branches
  const completed = ticketsClone.filter(
    (ticket) => Number(ticket.status) === Number(TICKET_STATUS.COMPLETED)
  );
  branchesClone = branchesClone.filter(
    (a) => a.reportStatus != null && Number(a.reportStatus)
  );

  const branchData = completed.filter((a) => a.branchId === branchId);

  const branchName =
    branchesClone.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);

  const servicesMap = {};
  branchServices.forEach((serviceId) => {
    servicesMap[serviceId] = [];
  });

  // Iterate over the data array and group objects by serviceId
  branchData.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const servicesData = Object.keys(servicesMap).map((serviceId) => {
    const serviceData = servicesMap[serviceId];

    const serviceName =
      services.find((service) => serviceId === service.id)?.name || "Unnamed";

    let totalServiceTime = 0;

    const agents = usersClone.filter(
      (user) =>
        user.organizationId === organizationId &&
        user.branchId === branchId &&
        user.services.includes(serviceId)
    );

    const agentsMap = {};
    agents.forEach((agent) => {
      agentsMap[agent.id] = [];
      return;
    });

    // Iterate over the data array and group objects by agentsId

    serviceData.forEach((a) => {
      const agentId = a.manage?.userId;

      if (Array.isArray(agentsMap[agentId])) {
        agentsMap[agentId].push(a);
      }
      totalServiceTime += a.sessionInSeconds;
    });

    //agents data

    const agentsData = Object.keys(agentsMap)
      .map((agentId) => {
        const agentData = agentsMap[agentId];
        const user = usersClone.find((user) => user.id === agentId);

        // Calculate the average

        const agentName = user ? user?.name + " " + user?.lastname : "Unnamed";

        // Iterate over the data array and group objects by date

        const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
        const chart = hoursArray
          .map((hour) => {
            const hourTickets = agentData.filter(
              (a) => dayjs.unix(a.created).format("h A") === hour
            );
            const serviceTime = hourTickets.reduce(
              (acc, curr) => acc + curr.sessionInSeconds,
              0
            );

            if (workDay.includes(hour))
              return {
                hour,
                serviceTime,
                hourlyAverage:
                  Math.round(serviceTime / hourTickets.length) || 0,
              };
            return null;
          })
          .filter(Boolean);
        const min = Math.min(...chart.map((a) => a.serviceTime)) || 0;
        const max = Math.max(...chart.map((a) => a.serviceTime)) || 0;
        const sum = chart.reduce((a, b) => a + b.serviceTime, 0) || 0;
        const average = sum / chart.length || 0;

        let totalPct = (sum / totalServiceTime) * 100 || 0;

        return {
          min,
          max,
          average,
          agentName,
          totalPct,
          sum,
          serviceName,
          chart,
        };
      })
      .sort((a, b) => {
        // Sort by total session time in descending order
        if (b.sum - a.sum !== 0) {
          return b.sum - a.sum;
        }
        // If totalSessionTime is the same, sort alphabetically by name
        return a.agentName.localeCompare(b.agentName);
      });

    return { serviceId, totalServiceTime, agentsData, serviceName };
  });

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
  console.log(title);

  return (
    <Box>
      <h2>{title}</h2>
      <Text>{branchServices.length} services.</Text>
      {servicesData.map((data, index) => {
        return (
          <AgentServiceTimePerServiceTable
            index={index}
            serviceData={data}
            branchIndex={branchIndex}
            reportIndex={reportIndex}
            branchName={branchName}
          />
        );
      })}
    </Box>
  );
};

export default BranchAgentServiceTimePerService;
