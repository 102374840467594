import { useSelector } from "react-redux";
import {
  ARRIVAL_RATES_FREQUENCIES,
  LEVELS,
} from "../store/constants";
import dayjs from "dayjs";
import { Box, Heading } from "@chakra-ui/react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { generateDateArray, getReadableKeyFromObject } from "../store/helpers";

const ArrivalRatesLineGraph = ({
  arrivalTimesPerDay,
  frequency,
  level,
  title,
  height,
}) => {
  const { hoursPerDay, workWeek, dateRange } = useSelector(
    (store) => store.defaultReducer
  );
  title =
    title ??
    getReadableKeyFromObject(LEVELS, level) +
      " Arrival Rates Per " +
      getReadableKeyFromObject(ARRIVAL_RATES_FREQUENCIES, frequency);

  let data = [];


  let start = dayjs.unix(dateRange[0]);
  console.log(start);

  Object.keys(arrivalTimesPerDay).forEach(function (dateString) {
    data.push({
      date: new Date(dateString).getDate(),
      fullDate: new Date(dateString),
      rate:
        frequency === ARRIVAL_RATES_FREQUENCIES.HOUR
          ? arrivalTimesPerDay[dateString] / hoursPerDay
          : arrivalTimesPerDay[dateString],
    });
  });
  data.sort((a, b) => a.date - b.date);


  const datesBetween = generateDateArray(dateRange[0], dateRange[1]);

  const finalData = datesBetween.map((fullDate, i) => {
    let date = dayjs(fullDate).date();
    const correspondingData = data.find(
      (entry) =>
        dayjs(entry.fullDate).format('MM-DD-YYYY') === dayjs(fullDate).format('MM-DD-YYYY')
    );

    if (workWeek.includes(new Date(fullDate).getDay()))
      return {
        date,
        rate: correspondingData?.rate || 0,
      };

      return null
  }).filter(Boolean);



  return (
    <Box width="100%" h={height || "300px"}>
      <ResponsiveContainer>
        <LineChart
          data={finalData}
          margin={{
            top: 0,
            right: 10,
            left: -10,
            bottom: 15,
          }}
        >
          <CartesianGrid stroke="#EBEBEB" vertical={false} />
          <XAxis
            dataKey="date"
            name="Day"
            axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
          />
          <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />

          <Tooltip />
          <Legend width="100%" />
          <Line
            type="monotone"
            strokeWidth={1.6}
            dataKey="rate"
            name="Total"
            stroke={"var(--blue)"}
            dot={{ fill: "var(--blue)", r: 2 }}
            activeDot={{ r: 4 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <Heading
        size="sm"
        fontWeight={400}
        fontSize="14px"
        color="#535353"
        mb="20px"
        textAlign="center"
      >
        {title}
      </Heading>
    </Box>
  );
};

export default ArrivalRatesLineGraph;
