import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import AgentServicesTable from "../tables/AgentServicesTable";
import _ from "lodash";
import { checkServiceHasChildren, mapHasData } from "../../../store/helpers";

const AgentServices = ({ branchData, branchId, reportIndex, branchIndex }) => {
  const { branches, hoursPerDay, servicesSubscriptions, services } = useSelector(
    (state) => state.defaultReducer
  );

  const servicesClone = _.cloneDeep(services);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  let branchServices = servicesSubscriptionsClone.find(
    (service) => service.branchId === branchId
  )?.services;

  branchServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => branchServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);

  const servicesMap = {};
  branchServices?.forEach((serviceId) => {
    servicesMap[serviceId] = [];
  });

  // Iterate over the data array and group objects by serviceId
  branchData.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const branchName =
    branches.find((branch) => branch.id === branchId)?.name ||
    `Branch-${branchId}`;

  const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`
  console.log(title)

  return (
    <Box>
      <h2>
        {title}
      </h2>
      {!mapHasData(servicesMap) && (
        <Text fontSize="sm">No data available for this report</Text>
      )}
      <Text>{branchServices?.length} services.</Text>

      {mapHasData(servicesMap) &&
        Object.keys(servicesMap).map((key, index) => {
          return (
            <AgentServicesTable
              key={index}
              branchIndex={branchIndex}
              reportIndex={reportIndex}
              serviceId={key}
              serviceData={servicesMap[key]}
              branchId={branchId}
              branchName={branchName}
              index={index}
            />
          );
        })}
    </Box>
  );
};

export default AgentServices;
