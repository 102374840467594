import React from 'react';
import {
    Box, Heading, Text,
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import _ from 'lodash';

import FilterForm from '../../components/FilterForm';
import AllTickets from "../../components/organization/reports/AllTickets";
import { getReportDescription } from '../../store/helpers';

const TicketsPage = ({ showFilterForm = true, reportIndex = 1 }) => {
    const { tickets, reportRange } = useSelector((state)=> state.defaultReducer);
    const ticketsClone = _.cloneDeep(tickets);
    const reportTitle = `${reportIndex}. Tickets`;
    console.log(reportTitle);
    return (
        <Box>
            { showFilterForm ? <FilterForm /> : null }
            {
                ticketsClone.length
                ?   <Box mb="20px">
                        <h1>{reportTitle}</h1>
                        <p>{getReportDescription(reportRange).Tickets}</p>
                    </Box>
                :   null
            }
            {
                ticketsClone.length
                ?   <AllTickets reportIndex={reportIndex} />
                :   null
            }
        </Box>
    );
};

export default TicketsPage;
  