import _ from "lodash";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  checkServiceHasChildren,
  generateHoursArray,
} from "../../../../store/helpers";
import ArrivalRatesPerServiceTable from "../../tables/daily/OrganizationArrivalRatesPerServiceTable";

const OrganizationArrivalRatesPerService = ({ reportIndex }) => {
  const {
    tickets,
    dateRange,
    workDay,
    branches,
    servicesSubscriptions,
    services,
    organizationId,
  } = useSelector((state) => state.defaultReducer);
  let branchesClone = _.cloneDeep(branches);
  const ticketsClone = _.cloneDeep(tickets);
  const servicesSubscriptionsClone = _.cloneDeep(servicesSubscriptions);
  const servicesClone = _.cloneDeep(services);

  const orgBranches = branchesClone.filter(
    (a) => a.organizationId === organizationId && Number(a.reportStatus)
  );
  let orgServices = [];
  orgBranches.forEach((branch) => {
    const branchServices =
      servicesSubscriptionsClone.find(
        (service) => service.branchId === branch.id
      )?.services || [];
    orgServices.push(...branchServices);
  });

  let updatedServices = checkServiceHasChildren(servicesClone)
    .filter(
      (service) => orgServices?.includes(service.id) && !service.hasChildren
    )
    .map((service) => service.id);

  const servicesMap = updatedServices.reduce((acc, serviceId) => {
    acc[serviceId] = [];
    return acc;
  }, {});

  ticketsClone.forEach((item) => {
    const serviceId = item.serviceId;

    if (Array.isArray(servicesMap[serviceId])) {
      servicesMap[serviceId].push(item);
    }
  });

  const servicesData = Object.keys(servicesMap)
    .map((serviceId) => {
      const serviceData = servicesMap[serviceId];

      const serviceName = services.find(
        (service) => service.id === serviceId
      )?.name;

      const hoursArray = generateHoursArray(dateRange[0], dateRange[1]);
      const chart = hoursArray
        .map((hour) => {
          const hourTickets = serviceData.filter(
            (a) => dayjs.unix(a.created).format("h A") === hour
          );

          if (workDay.includes(hour))
            return {
              hour,
              total: hourTickets.length,
            };
          return null;
        })
        .filter(Boolean);
      const min = Math.min(...chart.map((a) => a.total)) || 0;
      const max = Math.max(...chart.map((a) => a.total)) || 0;
      const total = chart.reduce((a, b) => a + b.total, 0) || 0;
      const average = chart.reduce((a, b) => a + b.total, 0) / chart.length || 0;
      const totalPct = (total / ticketsClone.length) * 100 || 0;

      return {
        serviceName,
        chart,
        min,
        max,
        total,
        average,
        totalPct,
      };
    })
    .sort((a, b) => {
      // Sort by totalTickets time in descending order
      if (b.total - a.total !== 0) {
        return b.total - a.total;
      }
      // If totalTickets is the same, sort alphabetically by name
      return a.serviceName.localeCompare(b.serviceName);
    });


  return (
    <>
      {servicesData.map((serviceData, index) => {
        return (
          <>
            <ArrivalRatesPerServiceTable
              index={index}
              reportIndex={reportIndex}
              serviceData={serviceData}
            />
          </>
        );
      })}
    </>
  );
};

export default OrganizationArrivalRatesPerService;
